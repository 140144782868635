import React, { Component } from 'react';
import {
  TableRow,
  TableCell,
  Avatar,
  Typography,
  IconButton,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'

const styles = theme => ({
  user: {
    display: 'flex',
    alignItems: 'center'
  },
  username: {
    marginLeft: 10
  }
})

@withStyles(styles)
@inject('CustomerStore')
@observer
class CustomerListItem extends Component {

  handleEditClick = e => {
    const { item } = this.props
    this.props.CustomerStore.initPayload(item)
    this.props.CustomerStore.setEditModalOpen()
  }

  render() {

    const { item, classes } = this.props
    const name = `${item.first_name} ${item.last_name}`
    const avatar = name[0]
    let location = ''
    if (item.city) {
      location += `${item.city}, `
    }
    if (item.province) {
      location += `${item.province}, `
    }
    if (item.country) {
      location += item.country
    }
     

    return (
      <TableRow>
        <TableCell component="th" scope="row">
          <div className={classes.user}>
            <span>
              <Avatar>{avatar}</Avatar>
            </span>
            <span className={classes.username}>
          <Typography variant='subtitle2' color='inherit'>
            {name}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {item.email}
          </Typography>
            </span>
          </div>
        </TableCell>
        <TableCell>{location}</TableCell>
        <TableCell>{item.orders}</TableCell>
        <TableCell>{item.points}</TableCell>
        <TableCell>{item.referrals}</TableCell>
        <TableCell>
          <IconButton aria-label="edit" size='small' onClick={this.handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

export default CustomerListItem;