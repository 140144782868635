import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import CustomerList from '../components/customer/CustomerList'
import CustomerListFilter from '../components/customer/CustomerListFilter'
import CustomerListPaginator from '../components/customer/CustomerListPaginator'
import CustomerEditModal from '../components/customer/CustomerEditModal'

const styles = theme => ({
  conatiner: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }
})

@withStyles(styles)
@inject('NavStore', 'CustomerStore')
@observer
class Customers extends Component {

  componentDidMount() {
    this.props.NavStore.setActiveMenu('customer')
    this.props.NavStore.setNavBarTitle('Customer')
    this.props.CustomerStore.fetchData()
  }
  
  render() {

    const { classes } = this.props

    return (
      <Container maxWidth='xl' className={classes.conatiner}>
        <Paper className={classes.paper}>
          <CustomerEditModal />
          <CustomerListFilter />
          <CustomerList />
          <CustomerListPaginator />
        </Paper>
      </Container>
    )
  }
}
export default Customers