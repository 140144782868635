import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'

@withRouter
@inject('CommonStore', 'ShopStore')
@observer
class Auth extends Component {

  componentWillMount() {
    const params = queryString.parse(this.props.location.search)
    const token = params.token
    this.props.ShopStore.setToken(token)
    this.props.ShopStore.setLogin(this.props.history)
  }

  render() {
    return (
      <div>
        this is auth page
      </div>
    )
  }
}


export default Auth
