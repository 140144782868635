import React, { Component } from 'react'
import { withTheme } from '@material-ui/core/styles';
import { XAxis, YAxis, Label, ResponsiveContainer, Bar, BarChart } from 'recharts'
import Typography from '@material-ui/core/Typography'
import { inject, observer } from 'mobx-react'


@withTheme
@inject('HomeStore')
@observer
class VisitChart extends Component {

  componentWillMount() {
    this.props.HomeStore.fetchVisitChart()
  }

  forceRender = src => {
    const data = src.sort()
    return data
  }

  render() {
    const { theme } = this.props
    const { visitChart } = this.props.HomeStore

    return (
      <React.Fragment>
        <Typography component="h2" variant="h6" color="textPrimary" gutterBottom>
          New visits
        </Typography>
        <ResponsiveContainer>
          <BarChart
            data={this.forceRender(visitChart)}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis dataKey="datetime" stroke={theme.palette.text.secondary} />
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                Visits
              </Label>
            </YAxis>
            <Bar dataKey='click_count' fill={theme.palette.primary.light} />
          </BarChart>
        </ResponsiveContainer>
      </React.Fragment>
    )
  }
}
export default VisitChart