import React, { Component } from 'react';
import './App.css';
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Router from './routes'
import Notification from './components/Notification';


@inject('CommonStore', 'RouteStore', 'ShopStore', 'CollectionStore', 'EarnPointRuleStore')
@withRouter
@observer
class App extends Component {

  componentWillMount() {
    this.props.RouteStore.setHistory(this.props.history)
  }

  componentDidMount() {
    this.props.CollectionStore.fetchCollections()
    const { earnPointRule } = this.props.EarnPointRuleStore
    if (earnPointRule.point_alias === undefined) {
      this.props.EarnPointRuleStore.fetchEarnPointRule()
    }
  }

  render() {
    return (
      <>
        <Notification />
        <Router />
      </>
    )
  }
}


export default App;
