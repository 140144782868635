import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination'
import { inject, observer } from 'mobx-react'

@inject('CustomerReportStore')
@observer
class CustomerPaginator extends Component {

  handlePageChange = (e, page) => {
    console.log(page)
    this.props.CustomerReportStore.handlePageChange(page)
  }

  handleChangeRowsPerPage = (e) => {
    this.props.CustomerReportStore.handleChangeRowsPerPage(e.target.value)
  }

  render() {

    const { count, page, limit } = this.props.CustomerReportStore

    return (
      <div>
        <TablePagination
          rowsPerPageOptions={[15, 30, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default CustomerPaginator;