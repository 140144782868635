import React, { Component } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles'
import PointAliasEditModal from './PointAliasEditModal'


const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    paddingTop: '0px',
  },
  inline: {
    display: 'inline',
  },
  listItem: {
    paddingTop: '0px'
  }
})

@withStyles(styles)
@inject('EarnPointRuleStore')
@observer
class PointAliasItem extends Component {

  handleEditClick = () => {
    this.props.EarnPointRuleStore.setAliasEditModalOpen()
    this.props.EarnPointRuleStore.initUpdatePayload()
  }

  render() {
    const { earnPointRule } = this.props.EarnPointRuleStore
    const { classes } = this.props
    return (
      <List className={classes.root}>
        <PointAliasEditModal />
        <ListItem className={classes.paddingTop}>
          <ListItemText
            primary={earnPointRule.point_alias}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  Example message will look like: You've earned 20 {earnPointRule.point_alias}!
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            <Button onClick={this.handleEditClick} variant='contained' size='small' color='primary'>
              Edit
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </List>
    )
  }
}
export default PointAliasItem