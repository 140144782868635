import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import Container from '@material-ui/core/Container';
import EarnSection from '../components/point/EarnSection'
import { withStyles } from '@material-ui/styles'
import RedeemSection from '../components/point/RedeemSection';
import Setting from '../components/point/Setting';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8)
  },
});


@withStyles(styles)
@inject('NavStore')
@observer
class Points extends Component {

  componentDidMount() {
    this.props.NavStore.setActiveMenu('point')
    this.props.NavStore.setNavBarTitle('Points')
  }

  render() {
    const { classes } = this.props
    return (
      <Container maxWidth="xl" className={classes.container}>
        <EarnSection />
        {/* <PaperSpacer /> */}
        <RedeemSection />
        {/* <PaperSpacer /> */}
        <Setting />
      </Container>
    );
  }
}
export default Points;