import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button 
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'

@inject('CustomerStore')
@observer
class CustomerEditModal extends Component {

  handleClose = () => {
    this.props.CustomerStore.setEditModalClose()
  }

  handleSave = () => {
    this.props.CustomerStore.update()
  }

  handlePointsChange = e => {
    this.props.CustomerStore.editPointsOnChange(e.target.value)
  }

  render() {

    const { payload, editModalOpen } = this.props.CustomerStore

    return (
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={editModalOpen}
        onClose={this.handleClose}
      >
        <DialogTitle>Adjust points</DialogTitle>
        <DialogContent>
          {/* <Typography variant='body2'>Ethan Luo</Typography> */}
          <TextField
            autoFocus
            margin="dense"
            type="number"
            variant='outlined'
            size='small'
            value={payload.points}
            onChange={this.handlePointsChange}
            fullWidth
          />
            
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="default">
            Close
          </Button>
          <Button onClick={this.handleSave} color="primary" variant='outlined'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default CustomerEditModal