import { observable, action } from 'mobx'
import axios from '../services/axios'


class CollectionStore {

  @observable collections = []

  @action setCollections = collections => {
    this.collections = [{
      'title': 'None',
      'id': ''
    }]
    this.collections.push(...collections)
  }

  @action fetchCollections = () => {
    axios.get('/collection').then(res => {
      if (res.data.success === 1) {
        this.setCollections(res.data.data)
      }
    })
  }
}

export default new CollectionStore()