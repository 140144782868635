import React, { Component } from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import { prizeTypeValue } from '../../constant'
import { priceFormat } from '../../utils/priceFormat';


const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
})

@withStyles(styles)
@inject('RedeemPointRuleStore', 'EarnPointRuleStore', 'ShopStore')
@observer
class RedeemRuleListItem extends Component {

  couponTitle = () => {
    const { item } = this.props
    switch (item.prize_type) {
      case prizeTypeValue.amount:
        const priceValueFormat = priceFormat(item.prize_value, this.props.ShopStore.shop)
        return `${priceValueFormat} off coupon`
      case prizeTypeValue.percentage:
        return `${item.prize_value}% off coupon`
      default:
        return ''
    }
  }

  handleEditClick = () => {
    this.props.RedeemPointRuleStore.initUpdatePayload(this.props.item)
    this.props.RedeemPointRuleStore.setUpdateModalOpen()
  }

  handleDisableClick = () => {
    this.props.RedeemPointRuleStore.disable(this.props.item)
    this.props.RedeemPointRuleStore.setStatusModifyAlertOpen()
  }

  handleEnableClick = () => {
    this.props.RedeemPointRuleStore.enable(this.props.item)
    this.props.RedeemPointRuleStore.itemToggleConfirm()
    // this.props.RedeemPointRuleStore.setStatusModifyAlertOpen()
  }

  render() {

    const { classes } = this.props
    const { item } = this.props
    const { earnPointRule } = this.props.EarnPointRuleStore

    return (
      <ListItem key={item.shop_points_redeem_rule_id}>
        <ListItemAvatar>
          {
            item.prize_type === prizeTypeValue.amount ?
              <img src={require('../../static/coupon-fill.svg')} height='26' />
            : <img src={require('../../static/coupon-f.svg')} height='26' />
          }
        </ListItemAvatar>
        <ListItemText
          primary={this.couponTitle()}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
              >
                {item.points} {earnPointRule.point_alias}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          {
            item.switch
              ? <Button onClick={this.handleDisableClick} size='small'>Disable</Button>
              : <Button onClick={this.handleEnableClick} size='small' color='secondary'>Enable</Button>
          }
          <Button onClick={this.handleEditClick} size='small' variant='contained' color='primary' style={{marginLeft: 10}}>
            Edit
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

export default RedeemRuleListItem