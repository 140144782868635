export const widgetIconLayout = {
  iconWithText: 'icon_with_text',
  icon: 'icon',
  text: 'text',
}

export const widgetPosition = {
  bottomLeft: 'bottom_left',
  bottomRight: 'bottom_right',
}

export const widgetIconId = {
  crown: 1,
  gift: 2,
  heart: 3,
  star: 4,
  tag: 5
}

export const prizeTypeValue = {
  amount: 1,
  percentage: 2,
  point: 3
}

export const rewardCostTypeMapping = {
  1: 'Register',
  2: 'Birthday',
  3: 'Place an order',
  4: 'Referred friend place an order',
  5: 'Points redeemed'
}

export const rewardPrizeTypeMapping = {
  1: ' off discount',
  2: '% off discount',
  3: ' points'
}

export const rewardPrizeTypeValueMapping = {
  amount: 1,
  percentage: 2,
  point: 3
}