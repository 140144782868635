import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import LandingPageItem from './LandingPageItem';
import StatusItem from './StatusItem';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    display: 'inline'
  },
})

@withStyles(styles)
@inject('ReferralStore')
@observer
class Setting extends Component {
  render() {
    const { classes } = this.props
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography  variant='h6' gutterBottom color='primary'>
                Referral setting
              </Typography>
              <br/>
              <Grid container spacing={4}>
              <Grid item xs={4}>
              <Typography variant='subtitle1' color='primary'>
                Landing page
              </Typography>
              <Typography variant="body2" gutterBottom>
                Choose the URL that you would like to send your referred customers to. All of the referral visits will be redirect to the home page by default. 
              </Typography>
              </Grid>
              <Grid item xs={8}>
                <LandingPageItem />
              </Grid>
              
              <Grid item xs={4}>
              <Typography variant='subtitle1' color='primary'>
                Referral Status
              </Typography>
              <Typography variant="body2" gutterBottom>
                Enable or disable your referral program.
              </Typography>
              </Grid>
              <Grid item xs={8}>
                <StatusItem />
              </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}
export default Setting