import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import RewardRule from './RewardRule'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    display: 'inline'
  },
})

@withStyles(styles)
@inject('ReferralStore')
@observer
class Rewards extends Component {
  render() {
    const { classes } = this.props
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography  variant='h6' gutterBottom color='primary'>
                Referral rewards
              </Typography>
              <br/>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                <Typography variant="body2" gutterBottom>
                  Reward your existing customers for referring their friends.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Customer gets their reward once the friend makes order.
                </Typography>
                </Grid>
                <Grid item xs={8}>
                  <RewardRule />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}
export default Rewards