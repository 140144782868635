import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody
} from '@material-ui/core'
import { rewardCostTypeMapping } from '../../constant'


@inject('ActivityStore')
@observer
class PointsEarnActivity extends Component {

  componentDidMount() {
    this.props.ActivityStore.fetchPointsEarn()
  }

  handlePageChange = (e, page) => {
    this.props.ActivityStore.pointsEarnPageOnChange(page)
  }

  handleChangeRowsPerPage = e => {
    this.props.ActivityStore.pointsEarnPageSizeOnChange(e.target.value)
  }
  render() {

    const { pointsEarnQuery, pointsEarnPage, pointsEarn } = this.props.ActivityStore
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Customer
                  </TableCell>
                  <TableCell>
                    Action
                  </TableCell>
                  <TableCell align='right'>
                    Points
                  </TableCell>
                  <TableCell align='right'>
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {pointsEarn.map(item => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.first_name} {item.last_name}
                  </TableCell>
                  <TableCell>
                    {rewardCostTypeMapping[item.cost_type]}
                  </TableCell>
                  <TableCell align='right'>
                    {item.prize_value}
                  </TableCell>
                  <TableCell align='right'>
                    {new Date(item.created).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
            
            <TablePagination 
              rowsPerPageOptions={[15, 30, 100]}
              component='div'
              count={pointsEarnQuery.count}
              rowsPerPage={pointsEarnQuery.limit}
              page={pointsEarnPage}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}
export default PointsEarnActivity