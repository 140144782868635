export const priceFormat = (price, shop) => {
  let result = ''
  if (shop.currency_symbol && shop.currency_symbol_first === 1) {
    result += shop.currency_symbol
  }
  const priceStr = Number(price).toLocaleString()
  result += priceStr
  result += ` ${shop.iso_code}`

  return result
}

export const priceOnlyFormat = price => {
  const result = Number(price).toLocaleString()
  return result
}