import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BarChartIcon from '@material-ui/icons/BarChart';
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import LinkIcon from '@material-ui/icons/Link';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import BrushIcon from '@material-ui/icons/Brush';
import MoodIcon from '@material-ui/icons/Mood';
import { withStyles } from '@material-ui/styles';
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'


const drawerWidth = 240;

const styles = theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0px',
    justifyContent: 'flex-end',
    zIndex: 9999,
    ...theme.mixins.toolbar,
  },
  toolbarTitle: {
    // justifyContent: 'flex-start'
    // marginLeft: 15,
    // fontFamily: 'Rubik, sans-serif'
  },
  toolbarIcon: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    marginRight: 0
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
});


@withRouter
@withStyles(styles)
@inject('CommonStore', 'NavStore', 'ReportTabStore', 'ActivityStore')
@observer
class Sidebar extends React.Component {

  handleSidebarClose = () => {
    this.props.CommonStore.setSidebarToggle()
  }

  handleThemeClick = () => {
    this.props.NavStore.setThemeMenuToggle()
  }

  handleReportItemClick = () => {
    this.props.ReportTabStore.setRoute('revenue')
  }

  handleActivityItemClick = () => {
    this.props.ActivityStore.setRoute('points_earn')
  }

  handleThemeChange = theme => {
    this.props.CommonStore.setTheme(theme)
  }

  render() {
    const classes = this.props.classes
    const { sidebarToggle } = this.props.CommonStore
    const { themeMenuToggle, activeMenu } = this.props.NavStore

    return (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !sidebarToggle && classes.drawerPaperClose),
          }}
          open={sidebarToggle}
        >
        <div className={classes.toolbar}>
            <img src={require('../static/brand.png')} alt="" height='30px' />
          {/* <div className={classes.toolbarTitle}>
            <Typography component="h1" variant="h5" noWrap color='textPrimary'>
              Shopaghetti
            </Typography>
          </div> */}
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleSidebarClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
        </div>
          <List>
            <ListItem button component={Link} to="/" selected={activeMenu === 'home'}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>

            <ListItem button component={Link} to="/referral" selected={activeMenu === 'referral'}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="Referrals" />
            </ListItem>

            <ListItem button component={Link} to="/point" selected={activeMenu === 'point'}>
              <ListItemIcon>
                <LoyaltyIcon />
              </ListItemIcon>
              <ListItemText primary="Points" />
            </ListItem>

            <ListItem 
              button 
              component={Link} 
              to="/activity/points_earn" 
              selected={activeMenu === 'activity'}
              onClick={this.handleActivityItemClick}
            >
              <ListItemIcon>
                <MoodIcon />
              </ListItemIcon>
              <ListItemText primary="Activity" />
            </ListItem>

            <ListItem button onClick={this.handleThemeClick}>
              <ListItemIcon>
                <BrushIcon />
              </ListItemIcon>
              <ListItemText primary="Theme" />
              {themeMenuToggle ? <IconExpandLess /> : <IconExpandMore />}
            </ListItem>
            <Collapse in={themeMenuToggle} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.nested}>
                <ListItem button className={classes.menuItem} component={Link} to='/widget' selected={activeMenu === 'widget'}>
                  <ListItemText inset primary="Widget" />
                </ListItem>
                <ListItem button className={classes.menuItem} component={Link} to='/popup' selected={activeMenu === 'popup'}>
                  <ListItemText inset primary="Popup" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem 
              button 
              component={Link} 
              to="/report/revenue" 
              selected={activeMenu === 'report'}
              onClick={this.handleReportItemClick}
            >
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
            <ListItem button component={Link} to="/customer" selected={activeMenu === 'customer'}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Customers" />
            </ListItem>
          </List>
          {/* <Divider />
          <List>
            <ListSubheader inset>Ongoing</ListSubheader>
            <ListItem button component={Link} to="/domain">
              <ListItemIcon>
                <DomainOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Domain" />
            </ListItem>
          </List> */}
        </Drawer>
    )
  }
}

export default Sidebar