import { observable, action } from 'mobx'
import axios from '../services/axios'


class ShopStore {

  @observable shop = {}
  @observable payload = {}
  @observable token = window.localStorage.getItem('token', '')

  @action setToken = token => {
    localStorage.setItem('token', token)
    this.token = token
    axios.defaults.headers.common['Authorization'] = 'JWT ' + this.token
  }

  @action setLogin = history => {
    history.replace('/')
  }

  @action setShop = shop => {
    this.shop = shop
  }

  @action fetchShop = () => {
    axios.get('/shop').then(res => {
      if (res.data.success === 1) {
        this.setShop(res.data.data)
        this.payloadInit()
      }
    })
  }

  @action payloadInit = () => {
    this.payload = {
      tutorial_passed: this.shop.tutorial_passed
    }
  }

  @action payloadOnChange = (k, v) => {
    this.payload[k] = v
  }

  @action update = () => {
    axios.put('/shop', this.payload).then(res => {
      if (res.data.success === 1) {
        this.fetchShop()
      }
    })
  }
}

export default new ShopStore()