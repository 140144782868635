import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch'
import { observer, inject } from 'mobx-react'



@inject('EarnPointRuleStore')
@observer
class SettingSwitchForm extends Component {

  handleChange = e => {
    const value = e.target.checked === true ? 1 : 0
    this.props.EarnPointRuleStore.updatePayloadOnChange('point_switch', value)
    this.props.EarnPointRuleStore.update()
    this.props.EarnPointRuleStore.fetchEarnPointRule()
  }

  render() {
    const { earnPointRule } = this.props.EarnPointRuleStore

    return (
      <div>
        <Switch
          color='primary'
          checked={earnPointRule.point_switch === 1 ? true : false}
          onChange={this.handleChange}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    )
  }
}
export default SettingSwitchForm