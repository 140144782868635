import { observable, action } from 'mobx'


class NavStore {
  @observable themeMenuToggle = false
  @observable activeMenu = 'home'
  @observable navBarTitle = 'Home'

  @action setThemeMenuToggle = () => {
    this.themeMenuToggle = !this.themeMenuToggle
  }

  @action setActiveMenu = menu => {
    this.activeMenu = menu
  }

  @action setNavBarTitle = title => {
    this.navBarTitle = title
  }
}

export default new NavStore()