import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'


const styles = theme => ({
  title: {
    flexGrow: 1,
  }
})

@withStyles(styles)
@inject('NavStore')
@observer
class Title extends Component {
  render() {

    const { navBarTitle } = this.props.NavStore
    const { classes } = this.props

    return (
      <Typography 
        component='h1' 
        variant='h6' 
        color='inherit' 
        noWrap
        className={classes.title}
      >
        {navBarTitle}
      </Typography>
    )
  }
}
export default Title