import React, { Component } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'


@inject('ActivityStore')
@observer
@withRouter
class ActivityTabbar extends Component {

  handleChange = (e, value) => {
    this.props.ActivityStore.setRoute(value)
    this.props.history.replace(`/activity/${value}`)
  }

  render() {
    const { route } = this.props.ActivityStore

    return (
      <div>
      <Tabs
        value={route}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
        >
        <Tab label="Points Earn" value='points_earn' />
        <Tab label="Points Redeem" value='points_redeem' />
        <Tab label="Referral" value='referral' />
      </Tabs>
      </div>
    )
  }
}
export default ActivityTabbar