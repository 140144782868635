import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

class PrizeSummary extends Component {

  render() {
    const { data } = this.props
    
    return (
      <React.Fragment>
        {data.map((item, index) => (
          <Typography key={index} variant='body1' gutterBottom>{item}</Typography>
        ))}     
      </React.Fragment>
    )
  }
}

export default PrizeSummary