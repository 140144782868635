import axios from 'axios';
import NProgress from 'nprogress';
import RouteStore from '../stores/route'
// import { Notification } from 'element-react';

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
axios.defaults.headers.common['Authorization'] = 'JWT ' + localStorage.getItem('token')


NProgress.configure({ minimum: 0.5 })
console.log(NProgress)

axios.interceptors.request.use(function (config) {
  NProgress.start()
  return config;
}, function (error) {
  NProgress.done()
  return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
  NProgress.done()
  return response
}, function (error) {
  let type = 'error'
  let msg = 'Server error, please contact the customer service.'

  if (error.response) {
    let status = error.response.status
    // if (status === 401) {
    //   RouteStore.history.replace('/login')
    // }
    type = status >= 500 && status <= 1000 ? 'warning' : 'error'
    msg = `${status}: ${error.response.data.msg}`
  } else {
    type = 'error'
    msg = error.message === 'Network Error' ? 'Network Error' : error.message
  }

  // Notification({
  //   title: type === 'warning' ? 'warning' : 'error',
  //   message: msg,
  //   duration: 2000,
  //   type: type
  // });

  NProgress.done()
  
  return Promise.reject(error);
})

export default axios;
