import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/styles'
import { observer, inject } from 'mobx-react'


const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
})

@withStyles(styles)
@inject('ReferralStore')
@observer
class LandingPageUpdateModal extends Component {

  handleSave = () => {
    this.props.ReferralStore.updateSetting()
    this.props.ReferralStore.setUpdateLandingPageModalClose()
  }

  handleClose = () => {
    this.props.ReferralStore.setUpdateLandingPageModalClose()
  }

  handleChange = e => {
    this.props.ReferralStore.updateSettingPayloadOnChange(e.target.name, e.target.value)
  }

  errorHelperText = () => {
    const { landingPageInputError } = this.props.ReferralStore
    if (landingPageInputError) {
      return 'Landing page should start with http:// or https://'
    }
    return ''
  }

  render() {
    const { updateSettingPayload, updateLandingPageModalOpen, landingPageInputError } = this.props.ReferralStore

    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={updateLandingPageModalOpen}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Landing page</DialogTitle>
        <DialogContent>
          
          {/* <Typography  variant='subtitle1' gutterBottom color='primary'>Alias</Typography> */}
          <TextField
            autoFocus
            margin="dense"
            type="text"
            name="landing_page"
            value={updateSettingPayload.landing_page}
            onChange={this.handleChange}
            error={landingPageInputError}
            helperText={this.errorHelperText()}
            fullWidth
          />
          <DialogContentText>
            All referred visit will be sent to {updateSettingPayload.landing_page}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="default">
            Close
          </Button>
          <Button disabled={landingPageInputError} onClick={this.handleSave} color="primary" variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default LandingPageUpdateModal