import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import { inject, observer } from 'mobx-react'
import { rewardCostTypeMapping } from '../../constant'

const styles = theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
})


@withStyles(styles)
@inject('HomeStore', 'ActivityStore')
@observer
class PointsEarning extends Component {

  formatAction = costType => {
    return rewardCostTypeMapping[costType]
  }

  handleClick = () => {
    this.props.ActivityStore.setRoute('points_earn')
  }

  render() {
    const { pointsEarningActivity } = this.props.HomeStore

    const { classes } = this.props
    return (
      <React.Fragment>
        <Typography component="h2" variant="h6" color='textPrimary' gutterBottom>
          Points earning
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
              <TableCell align="right">Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pointsEarningActivity.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.first_name} {row.last_name}</TableCell>
                <TableCell>{this.formatAction(row.cost_type)}</TableCell>
                <TableCell align='right'>{row.prize_value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.seeMore}>
          <Link 
            color="primary" 
            component={RouteLink} 
            to='/activity/points_earn'
            onClick={this.handleClick}
          >
            View all points activity
          </Link>
        </div>
      </React.Fragment>
    )
  }
}
export default PointsEarning