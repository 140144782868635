import { Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import React, { Component } from 'react'
import ReferralPaginator from './ReferralPaginator'
import { inject, observer } from 'mobx-react'

@inject('ReferralReportStore')
@observer
class ReferralTable extends Component {

  datetimeFormat = datetime => {
    if (datetime.length === 13) {
      return `${datetime}:00`
    } else {
      return datetime
    }
  }

  render() {

    const { data } = this.props.ReferralReportStore

    return (
      <Paper>
        <Table size='small'>
        <TableHead>
            <TableRow>
              <TableCell>
                {/* <TableSortLabel
                  active={campaignQuery.orderBy === 'id'}
                  direction={campaignQuery.orderBy === 'id' ? campaignQuery.order: 'desc'}
                  onClick={(e) => this.handleSortClick('id', e)}
                >
                  Id
                </TableSortLabel> */}
                Date
              </TableCell>
              <TableCell align='right'>
                Click count
              </TableCell>
              <TableCell align='right'>
                Unique vistor
              </TableCell>
              <TableCell align='right'>
                Orders
              </TableCell>
              <TableCell align='right'>
                Conversion rate
              </TableCell>
              <TableCell align='right'>
                Total revenue($)
              </TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>

          {data.map((item, i) => (
            <TableRow key={i}>
            <TableCell component="th" scope="row">
              {this.datetimeFormat(item.datetime)}
            </TableCell>
            <TableCell align='right'>{item.click_count}</TableCell>
            <TableCell align='right'>{item.uv}</TableCell>
            <TableCell align='right'>{item.orders}</TableCell>
            <TableCell align='right'>{(item.orders / item.click_count * 100).toFixed(2)}%</TableCell>
            <TableCell align='right'>{item.revenue}</TableCell>
          </TableRow>
          ))}
          </TableBody>
          

        </Table>
        <ReferralPaginator />
      </Paper>
    )
  }
}
export default ReferralTable