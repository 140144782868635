import { observable, action } from 'mobx'

class NotificationStore {

  @observable open = false
  @observable severity = 'success'
  @observable color = 'info'
  @observable message = ''
  @observable messageOpen = window.localStorage.getItem('messageOpen') === '0' ? false : true
  // @observable messageOpen = true

  // @action setMessageOpen = () => {
  //   this.messageOpen = true
  // }

  // @action setMessageClose = () => {
  //   this.messageOpen = false
  // }

  @action setMessageClose = () => {
    window.localStorage.setItem('messageOpen', '0')
    this.messageOpen = false
  }

  @action setMessageOpen = () => {
    window.localStorage.setItem('messageOpen', '1')
    this.messageOpen = true
  }

  @action setOpen = () => {
    this.open = true
  }

  @action setClose = () => {
    this.open = false
  }

  @action notify = (severity='success', color='info', message) => {
    this.setClose()
    this.severity = severity
    this.color = color
    this.message = message
    this.setOpen()
  }
}

export default new NotificationStore()