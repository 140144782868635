import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import ReferralChart from './referral/ReferralChart';
import ReferralFilter from './referral/ReferralFilter';
import ReferralTable from './referral/ReferralTable';


@inject('ReportTabStore', 'ReferralReportStore')
@observer
class ReferralReport extends Component {

  componentDidMount() {
    if (this.props.ReferralReportStore.data.length === 0) {
      this.props.ReferralReportStore.fetchData()
    }
  }

  render() {

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferralFilter />
        </Grid>
        <Grid item xs={12}>
          <ReferralChart />
        </Grid>
        <Grid item xs={12}>
          <ReferralTable />
        </Grid>
      </Grid>
    )
  }
}
export default ReferralReport