import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import CssBaseline from '@material-ui/core/CssBaseline'
import ShopStore from './stores/shop'
import CommonStore from './stores/common'
import RouteStore from './stores/route'
import NavStore from './stores/nav'
import EarnPointRuleStore from './stores/earnPointRule'
import RedeemPointRuleStore from './stores/redeemPointRule'
import ReferralStore from './stores/referral'
import WidgetStore from './stores/widget'
import PopupStore from './stores/popup'
import CollectionStore from './stores/collection'
import NotificationStore from './stores/notification'
import ReportTabStore from './stores/reportTab'
import CustomerStore from './stores/customer'
import RevenueReportStore from './stores/revenueReport'
import ReferralReportStore from './stores/referralReport'
import CustomerReportStore from './stores/customerReport'
import HomeStore from './stores/home'
import TutorialStore from './stores/tutorial'
import ActivityStore from './stores/activity'
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'
import '../node_modules/nprogress/nprogress.css';


const stores = {
  ShopStore,
  CommonStore,
  RouteStore,
  NavStore,
  EarnPointRuleStore,
  RedeemPointRuleStore,
  ReferralStore,
  WidgetStore,
  PopupStore,
  NotificationStore,
  CollectionStore,
  ReportTabStore,
  CustomerStore,
  RevenueReportStore,
  ReferralReportStore,
  CustomerReportStore,
  HomeStore,
  ActivityStore,
  TutorialStore
}

const Root = (
  <Provider {...stores}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(
  Root,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
