import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tutorial from '../components/tutorial/Tutorial'
import Dashboard from '../components/home/Dashboard'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
})

@withStyles(styles)
@inject('NavStore', 'HomeStore', 'ReportTabStore', 'ActivityStore', 'ShopStore')
@observer
class Home extends Component {

  componentDidMount() {
    this.props.NavStore.setActiveMenu('home')
    this.props.NavStore.setNavBarTitle('Home')
  }

  render() {

    const { classes } = this.props
    const { tutorial_passed } = this.props.ShopStore.shop

    if (tutorial_passed === 1) {
      return (
        <Dashboard />
      )
    } else if (tutorial_passed === 0) {
      return (
      <Container maxWidth='xl' className={classes.container}>
        <Typography variant='h5' color='textPrimary'>You're almost there!</Typography>
        <Typography variant='subtitle2' color='textSecondary' gutterBottom>A few steps to start your Point & Referral Program.</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tutorial />
          </Grid>
        </Grid>
      </Container>
      )
    } else {
      return ('')
    }
  }
}
export default Home
