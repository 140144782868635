import { Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import React, { Component } from 'react'
import RevenuePaginator from './RevenuePaginator'
import { inject, observer } from 'mobx-react'

@inject('RevenueReportStore')
@observer
class RevenueTable extends Component {

  datetimeFormat = datetime => {
    if (datetime.length === 13) {
      return `${datetime}:00`
    } else {
      return datetime
    }
  }
  
  render() {

    const { data } = this.props.RevenueReportStore
    
    return (
      <Paper>
        <Table size='small'>
        <TableHead>
            <TableRow>
              <TableCell>
                {/* <TableSortLabel
                  active={campaignQuery.orderBy === 'id'}
                  direction={campaignQuery.orderBy === 'id' ? campaignQuery.order: 'desc'}
                  onClick={(e) => this.handleSortClick('id', e)}
                >
                  Id
                </TableSortLabel> */}
                Datetime
              </TableCell>
              <TableCell align='right'>
                Revenue via referral customer rewarded
              </TableCell>
              <TableCell align='right'>
                Revenue via referred friend
              </TableCell>
              <TableCell align='right'>
                Revenue via points redeem reward
              </TableCell>
              <TableCell align='right'>
                Total revenue
              </TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
          {data.map((item, i) => (
            <TableRow key={i}>
            <TableCell component="th" scope="row">
              {this.datetimeFormat(item.datetime)}
            </TableCell>
            <TableCell align='right'>{item.referral_customer_revenue}</TableCell>
            <TableCell align='right'>{item.referral_friend_revenue}</TableCell>
            <TableCell align='right'>{item.points_redeem_revenue}</TableCell>
            <TableCell align='right'>
              {(item.points_redeem_revenue + item.referral_customer_revenue + item.referral_friend_revenue).toFixed(2)
              }
            </TableCell>
          </TableRow>
          ))}
          </TableBody>

        </Table>
        <RevenuePaginator />
      </Paper>
    )
  }
}
export default RevenueTable