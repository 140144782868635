import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'

const styles = theme => ({
  search: {
    width: 350,
    marginRight: theme.spacing(2)
  }
})

@withStyles(styles)
@inject('CustomerStore')
@observer
class CustomerListFilter extends Component {

  handleChange = e => {
    this.props.CustomerStore.setQ(e.target.value)
  }

  handleSearchClick = e => {
    this.props.CustomerStore.fetchData()
  }

  render() {

    const { classes } = this.props
    const { q } = this.props.CustomerStore

    return (
      <div>
        <TextField
          className={classes.search}
          value={q}
          onChange={this.handleChange}
          placeholder='Search customers'
          variant="outlined"
          size='small'
        />
        <Button 
          variant='outlined'
          color='primary'
          size='large'
          onClick={this.handleSearchClick}
        >
          Search
        </Button>
      </div>
    );
  }
}

export default CustomerListFilter;