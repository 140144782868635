import { observable, action, computed } from 'mobx'
import axios from '../services/axios'


class CustomerStore {

  @observable data = []
  @observable count = 0
  @observable limit = 10
  @observable offset = 0
  @observable q = ''
  @observable payload = {}
  @observable editModalOpen = false

  @action setEditModalClose = () => {
    this.editModalOpen = false
  }

  @action setEditModalOpen = () => {
    this.editModalOpen = true
  }

  @action initPayload = customer => {
    const { shop_id, customer_id, points } = customer
    this.payload = {
      shop_id,
      customer_id,
      points
    }
  }

  @action editPointsOnChange = value => {
    this.payload['points'] = value
  }

  @action update = () => {
    axios.put('/adjust_points', this.payload).then(res => {
      if (res.data.success === 1) {
        this.setEditModalClose()
        this.fetchData()
      }
    })
  }

  @action setData = data => {
    if (data.length === 1 && data[0].customer_id === null) {
      this.data = []
    } else {
      this.data = []
      this.data.push(...data)
      if (data.length > 0) {
        this.setCount(data[0].total_count)
      }
    }
  }

  @action setCount = count => this.count = count

  @action setLimit = limit => this.limit = limit

  @action setOffset = offset => this.offset = offset

  @action setQ = q => this.q = q

  @computed get api() {
    return `/customer?limit=${this.limit}&offset=${this.offset}&q=${this.q}`
  }

  @computed get page() {
    return Math.floor(this.offset / this.limit)
  }

  @action fetchData = () => {
    axios.get(this.api).then(res => {
      if (res.data.success === 1) {
        this.setData(res.data.data)
      }
    })
  }

  @action handleChangePage = currentPage => {
    const offset = currentPage * this.limit
    this.setOffset(offset)
    this.fetchData()
  }

  @action handleChangePageSize = pageSize => {
    this.setLimit(pageSize)
    this.fetchData()
  }
}

export default new CustomerStore()