import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import RedeemRuleList from './RedeemRuleList';
import RedeemRuleCreateModal from './RedeemRuleCreateModal'
import { Button } from '@material-ui/core';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    display: 'inline'
  },
  add: {
    display: 'inline',
    float: 'right'
  }
})

@withStyles(styles)
@inject('RedeemPointRuleStore')
@observer
class RedeemSection extends Component {

  handlePageChange = (e, page) => {
    this.props.RedeemPointRuleStore.setPage(page)
    this.props.RedeemPointRuleStore.fetchRules()
  }

  componentWillMount() {
    this.props.RedeemPointRuleStore.fetchRules()
  }

  handleCreateClick = () => {
    this.props.RedeemPointRuleStore.initCreatePayload()
    this.props.RedeemPointRuleStore.setCreateModalOpen()
  }

  render() {
    const { classes } = this.props
    const { totalPage } = this.props.RedeemPointRuleStore

    return (
      <div>
        <RedeemRuleCreateModal />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div>
              <Typography  variant="h6" gutterBottom color='primary' className={classes.header}>
                Redeem Points
              </Typography>
              <Button 
                className={classes.add} 
                size='small' 
                color='primary'
                variant='outlined'
                onClick={this.handleCreateClick}
              >
                Add ways to redeem
              </Button>
              </div>
              <br />
              <Grid container spacing={4}>
                <Grid item xs={4}>
                <Typography variant="body2" gutterBottom>
                  Manage the rewards your customers can redeem with the points they’ve earned. 
                </Typography>
                <Typography variant="body2" gutterBottom>
                </Typography>
                </Grid>
                <Grid item xs={8}>
                  <RedeemRuleList />
                  <Pagination size='small' count={totalPage} onChange={this.handlePageChange} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid> 
      </div>
    )
  }
}
export default RedeemSection