import React, { Component } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

class Crown extends Component {
  render() {
    return (
      <SvgIcon {...this.props} color={this.props.color}>
        <path d="M5 16L3 5L8.5 10L12 4L15.5 10L21 5L19 16H5M19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z" />
      </SvgIcon>
    )
  }
}

export default Crown