import React, { Component } from 'react'
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import RedeemRuleListItem from './RedeemRuleListItem';
import RedeemRuleDisableAlert from './RedeemRuleDisableAlert';
import RedeemRuleUpdateModal from './RedeemRuleUpdateModal';


const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
})

@withStyles(styles)
@inject('RedeemPointRuleStore')
@observer
class RedeemRuleList extends Component {

  render() {
    const { classes } = this.props
    const { rules } = this.props.RedeemPointRuleStore
    return (
      <List className={classes.root}>
        <Divider />
        <RedeemRuleUpdateModal />
        <RedeemRuleDisableAlert />
        {rules.map((item) => (
          <div key={item.shop_points_redeem_rule_id}>
            <RedeemRuleListItem item={item} />
            <Divider />
          </div>
        ))} 
      </List>
    )
  }
}
export default RedeemRuleList