import { observable, action, computed } from 'mobx'
import axios from '../services/axios'

class ActivityStore {

  @observable route = 'points_earn'
  @observable pointsRedeem= []
  @observable pointsEarn= []
  @observable referral= []
  @observable pointsRedeemQuery = {
    count: 0,
    limit: 15,
    offset: 0
  }
  @observable pointsEarnQuery = {
    count: 0,
    limit: 15,
    offset: 0
  }
  @observable referralQuery = {
    count: 0,
    limit: 15,
    offset: 0
  }

  @computed get pointsRedeemPage() {
    return Math.floor(this.pointsRedeemQuery.offset / this.pointsRedeemQuery.limit)
  }

  @computed get pointsEarnPage() {
    return Math.floor(this.pointsEarnQuery.offset / this.pointsEarnQuery.limit)
  }

  @computed get referralPage() {
    return Math.floor(this.referralQuery.offset / this.referralQuery.limit)
  }

  @computed get pointsRedeemApi() {
    return `/reward?limit=${this.pointsRedeemQuery.limit}&offset=${this.pointsRedeemQuery.offset}&cost_type=5`
  }

  @computed get pointsEarnApi() {
    return `/reward?limit=${this.pointsEarnQuery.limit}&offset=${this.pointsEarnQuery.offset}&prize_type=3`
  }

  @computed get referralApi() {
    return `/reward/referral?limit=${this.referralQuery.limit}&offset=${this.referralQuery.offset}`
  }

  @action setRoute = route => {
    this.route = route
  }

  @action pointsRedeemPageOnChange = page => {
    this.pointsRedeemQuery.offset = this.pointsRedeemQuery.limit * page
    this.fetchPointsRedeem()
  }

  @action pointsEarnPageOnChange = page => {
    this.pointsEarnQuery.offset = this.pointsEarnQuery.limit * page
    this.fetchPointsEarn()
  }

  @action referralPageOnChange = page => {
    this.referralQuery.offset = this.referralQuery.limit * page
    this.fetchReferral()
  }

  @action pointsRedeemPageSizeOnChange = pageSize => {
    this.pointsRedeemQuery.limit = pageSize
    this.fetchPointsRedeem()
  }

  @action pointsEarnPageSizeOnChange = pageSize => {
    this.pointsEarnQuery.limit = pageSize
    this.fetchPointsEarn()
  }

  @action referralPageSizeOnChange = pageSize => {
    this.referralQuery.limit = pageSize
    this.fetchReferral()
  }

  @action fetchPointsRedeem = () => {
    axios.get(this.pointsRedeemApi).then(res => {
      if (res.data.success === 1) {
        this.pointsRedeem = []
        this.pointsRedeem.push(...res.data.data.result)
        this.pointsRedeemQuery.count = res.data.data.total_count
      }
    })
  }

  @action fetchPointsEarn = () => {
    axios.get(this.pointsEarnApi).then(res => {
      if (res.data.success === 1) {
        this.pointsEarnQuery.count = res.data.data.total_count
        this.pointsEarn = []
        this.pointsEarn.push(...res.data.data.result)
      }
    })
  }

  @action fetchReferral = () => {
    axios.get(this.referralApi).then(res => {
      if (res.data.success === 1) {
        this.referral = []
        this.referral.push(...res.data.data.result)
        this.referralQuery.count = res.data.data.total_count
      }
    })
  }
}


export default new ActivityStore()