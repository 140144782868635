import React, { Component } from 'react'
import CustomerChart from './customer/CustomerChart';
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import CustomerTable from './customer/CustomerTable'
import CustomerFilter from './customer/CustomerFilter'


@inject('CustomerReportStore')
@observer
class CustomerReport extends Component {

  componentDidMount() {
    if (this.props.CustomerReportStore.data.length === 0) {
      this.props.CustomerReportStore.fetchData()
    }
  }

  render() {

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomerFilter />
        </Grid>
        <Grid item xs={12}>
          <CustomerChart />
        </Grid>
        <Grid item xs={12}>
          <CustomerTable />
        </Grid>
      </Grid>
    )
  }
}
export default CustomerReport