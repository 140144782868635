import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination'
import { inject, observer } from 'mobx-react'

@inject('CustomerStore')
@observer
class CustomerListPaginator extends Component {

  handleChangePage = (e, currentPage) => {
    console.log(currentPage)
    this.props.CustomerStore.handleChangePage(currentPage)
  }

  handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value
    this.props.CustomerStore.handleChangePageSize(pageSize)
  }

  render() {

    const { count, limit, page } = this.props.CustomerStore
    return (
      <div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default CustomerListPaginator;