import { observable, action, computed, autorun } from 'mobx'
import axios from '../services/axios'
import NotificationStore from './notification'
import { rewardPrizeTypeValueMapping } from '../constant'


class ReferralStore {

  @observable referralSetting = {}
  @observable customerPrizeRule = {}
  @observable friendPrizeRule = {}
  @observable updatePrizeRuleType = ''
  @observable updatePrizeRulePayload = {}
  @observable updatePrizeRuleModalOpen = false
  @observable updateSettingPayload = {}
  @observable updateLandingPageModalOpen = false
  @observable prizeRuleToggleAlertOpen = false
  @observable landingPageInputError = false


  @action setPrizeRuleToggleAlertOpen = () => {
    this.prizeRuleToggleAlertOpen = true
  }

  @action setPrizeRuleToggleAlertClose = () => {
    this.prizeRuleToggleAlertOpen = false
  }

  @action setUpdateLandingPageModalOpen = () => {
    this.updateLandingPageModalOpen = true
  }

  @action setUpdateLandingPageModalClose = () => {
    this.updateLandingPageModalOpen = false
  }

  @action setUpdateModalOpen = () => {
    this.updatePrizeRuleModalOpen = true
  }

  @action setUpdateModalClose = () => {
    this.updatePrizeRuleModalOpen = false
  }

  @action setData = data => {
    this.referralSetting = data
  }

  @action setCustomerPrizeRule = rule => {
    this.customerPrizeRule = rule
  }

  @action setFriendPrizeRule = rule => {
    this.friendPrizeRule = rule
  }

  @action fetchData = () => {
    axios.get('/referral').then(res => {
      if (res.data.success === 1) {
        this.setData(res.data.data)
        this.setCustomerPrizeRule(res.data.data.customer_prize_rule)
        this.setFriendPrizeRule(res.data.data.friend_prize_rule)
      }
    })
  }

  @action setUpdatePrize = type => {
    this.updatePrizeRuleType = type
  }

  @action initUpdatePrizeRulePayload = (type) => {
    this.setUpdatePrize(type)
    const keys = [
      'prize_type',
      'prize_value',
      'apply_to',
      'apply_to_collection_id',
      'min_required',
      'discount_code_prefix',
    ]
    keys.map(item => {
      if (type === 'customer') {
        this.updatePrizeRulePayload[item] = this.customerPrizeRule[item]
      } else if (type === 'friend') {
        this.updatePrizeRulePayload[item] = this.friendPrizeRule[item]
      }
      return 1
    })

  }

  @computed get prizeRuleUpdateApi() {
    return `/update_referral_prize_rule?type=${this.updatePrizeRuleType}`
  }

  @action prizeRuleUpdateOnChange = (k, v) => {
    this.updatePrizeRulePayload[k] = v
  }

  prefix = autorun(() => {
    const prefix = `REF-${this.updatePrizeRuleType.toUpperCase()}-${this.updatePrizeRulePayload.prize_value}${this.updatePrizeRulePayload.prize_type === rewardPrizeTypeValueMapping.percentage ? '%' : ''}OFF`
    this.prizeRuleUpdateOnChange('discount_code_prefix', prefix)
  })

  @action prizeRuleUpdateSubmit = () => {
    axios.post(this.prizeRuleUpdateApi, this.updatePrizeRulePayload).then(res => {
      if (res.data.success === 1) {
        this.fetchData()
        this.setUpdateModalClose()
        NotificationStore.notify(
          'success',
          'info',
          'Prize rule updated.'
        )
      }
    })
  }

  @action initUpdateSettingPayload = () => {
    const keys = [
      'customer_prize_switch',
      'friend_prize_switch',
      'landing_page',
      'switch',
    ]
    keys.map(item => {
      const v = this.referralSetting[item]
      this.updateSettingPayload[item] = v
      return 1
    })
  }

  @action setLandingPageError= v => {
    const startWithProtocol = v.startsWith('http://') || v.startsWith('https://')
    this.landingPageInputError = !startWithProtocol
  }

  @action updateSettingPayloadOnChange = (k, v) => {
    if (k === 'landing_page') {
      this.setLandingPageError(v)
    }
    this.updateSettingPayload[k] = v
  }

  @action updateSetting = () => {
    axios.put('/referral', this.updateSettingPayload).then(res => {
      if (res.data.success === 1) {
        this.fetchData()
        NotificationStore.notify(
          'success',
          'info',
          'Referral settings updated.'
        )
      }
    })
  }
}

export default new ReferralStore()