import React, { Component } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RewardRuleUpdateModal from './RewardRuleUpdateModal'
import { withStyles } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import PrizeRuleToggleAlert from './PrizeRuleToggleAlert';
import { prizeTypeValue } from '../../constant';
import { priceFormat } from '../../utils/priceFormat'

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
})


@withStyles(styles)
@inject('ReferralStore', 'ShopStore', 'EarnPointRuleStore')
@observer
class RewardRule extends Component {

  prizeSummary = rule => {
    const { point_alias } = this.props.EarnPointRuleStore.earnPointRule
    console.log(this.props.EarnPointRuleStore.earnPointRule)
    switch (rule.prize_type) {
      case prizeTypeValue.point:
        return `${rule.prize_value} ${point_alias}`
      case prizeTypeValue.percentage:
        return `${rule.prize_value}% off coupon`
      case prizeTypeValue.amount:
        const prizeValueFormat = priceFormat(rule.prize_value, this.props.ShopStore.shop)
        return `${prizeValueFormat} off coupon`
      default:
        return ''
    }
  }

  icon = rule => {
    switch (rule.prize_type) {
      case prizeTypeValue.point:
        return (
          <img src={require('../../static/hand-coin-fill.svg')} height='26' />
        )
      case prizeTypeValue.percentage:
        return (
          <img src={require('../../static/coupon-f.svg')} height='26' />
        )
      case prizeTypeValue.amount:
        return (
          <img src={require('../../static/coupon-fill.svg')} height='26' />
        )
      default:
        return ''
    }
  }

  handleEditClick = type => {
    this.props.ReferralStore.initUpdatePrizeRulePayload(type)
    this.props.ReferralStore.setUpdateModalOpen()
  }

  handleDisableClick = (k, v) => {
    this.props.ReferralStore.initUpdateSettingPayload()
    this.props.ReferralStore.setPrizeRuleToggleAlertOpen()
    this.props.ReferralStore.updateSettingPayloadOnChange(k, v)
  }

  handleEnableClick = (k, v) => {
    this.props.ReferralStore.initUpdateSettingPayload()
    this.props.ReferralStore.updateSettingPayloadOnChange(k, v)
    this.props.ReferralStore.updateSetting()
  }

  render() {

    const { classes } = this.props
    const { referralSetting, customerPrizeRule, friendPrizeRule } = this.props.ReferralStore

    return (
      <div>
        <RewardRuleUpdateModal />
        <PrizeRuleToggleAlert />
        <List className={classes.root}>
          <Divider />
          <ListItem>
            <ListItemAvatar>
              {this.icon(customerPrizeRule)}
            </ListItemAvatar>
            
            <ListItemText
              primary='REFERRING CUSTOMER REWARD'
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                  >
                    {referralSetting.customer_prize_switch
                      ? 'Customer gets ' + this.prizeSummary(customerPrizeRule)
                      : 'Customer reward disabled'
                    }
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              {referralSetting.customer_prize_switch
                ? <Button size='small' onClick={() => this.handleDisableClick('customer_prize_switch', 0)}>Disable</Button>
                : <Button size='small' color='secondary' onClick={() => this.handleEnableClick('customer_prize_switch', 1)}>Enable</Button>
              }
              <Button size='small' style={{marginLeft: 10}} color='primary' variant='contained' onClick={() => this.handleEditClick('customer')}>
                Edit
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemAvatar>
              {this.icon(friendPrizeRule)}
            </ListItemAvatar>
            <ListItemText
              primary="REFERRED FRIEND REWARD"
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                  >
                    {referralSetting.friend_prize_switch
                      ? 'Friend gets ' + this.prizeSummary(friendPrizeRule)
                      : 'Friend reward disabled'
                    }
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              {referralSetting.friend_prize_switch
                ? <Button size='small' onClick={() => this.handleDisableClick('friend_prize_switch', 0)}>Disable</Button>
                : <Button size='small' color='secondary' onClick={() => this.handleEnableClick('friend_prize_switch', 1)}>Enable</Button>
              }
              <Button size='small' style={{marginLeft: 10}} color='primary' variant='contained' onClick={() => this.handleEditClick('friend')}>
                Edit
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </List>
      </div>
    )
  }
}
export default RewardRule