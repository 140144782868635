import React, { Component } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'


@inject('ReportTabStore')
@observer
@withRouter
class ReportTabbar extends Component {

  handleChange = (e, value) => {
    this.props.ReportTabStore.setRoute(value)
    this.props.history.replace(`/report/${value}`)
  }

  render() {

    const { route } = this.props.ReportTabStore

    return (
      <div>
      <Tabs
        value={route}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
        // centered
        >
        <Tab label="Revenue" value='revenue' />
        <Tab label="Referral" value='referral' />
        <Tab label="Customer" value='customer' />
      </Tabs>
      </div>
    )
  }
}
export default ReportTabbar