import { observable, action } from 'mobx'


class CommonStore {

  @observable sidebarToggle = true
  @observable token = window.localStorage.getItem('token')
  // @observable nav = {
  //   campaign: true,
  //   report: false,
  // }

  // @action setNav = () => {
  //   this.report = true
  //   this.campaign = false
  // }

  @action setSidebarToggle() {
    this.sidebarToggle = !this.sidebarToggle
  }

}

export default new CommonStore()