import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer, inject } from 'mobx-react'

@inject('EarnPointRuleStore')
@observer
class EarnRuleStatusModifyAlertDialog extends Component {

  handleOkClick = () => {
    this.props.EarnPointRuleStore.update()
    this.props.EarnPointRuleStore.setStatusModifyAlertClose()
  }

  handleCancelClick = () => {
    this.props.EarnPointRuleStore.setStatusModifyAlertClose()
  }

  render() {

    const { statusModifyAlertOpen } = this.props.EarnPointRuleStore

    return (
      <Dialog
        open={statusModifyAlertOpen}
        onClose={this.handleCancelClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Disabled the points earn rule?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Customers will no longer earn points via this rule.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelClick} color="default">
            Cancel
          </Button>
          <Button onClick={this.handleOkClick} variant="contained" color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default EarnRuleStatusModifyAlertDialog