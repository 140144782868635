import { observable, action } from 'mobx'

class TutorialStore {

  @observable currentStep = 0
  @observable totalStep = 5

  @action setNext = () => {
    this.currentStep = this.currentStep + 1
  }

  @action setBack = () => {
    this.currentStep = this.currentStep - 1
  }

  @action setStep = step => {
    this.currentStep = step
  }

  @action setTutorialFinished = () => {
    console.log(11)
  }
}

export default new TutorialStore