import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer, inject } from 'mobx-react'

@inject('ReferralStore')
@observer
class PrizeRuleToggleAlert extends Component {

  handleOkClick = () => {
    this.props.ReferralStore.updateSetting()
    this.props.ReferralStore.setPrizeRuleToggleAlertClose()
  }

  handleCancelClick = () => {
    this.props.ReferralStore.setPrizeRuleToggleAlertClose()
  }

  render() {

    const { prizeRuleToggleAlertOpen } = this.props.ReferralStore

    return (
      <Dialog
        open={prizeRuleToggleAlertOpen}
        onClose={this.handleCancelClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Disable the referral reward?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Customer or friend will no longer get reward via referral.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Button variant='contained' onClick={this.handleOkClick} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default PrizeRuleToggleAlert