import React, { Component } from 'react'
import {
  Button
} from '@material-ui/core'
import { observer, inject } from 'mobx-react'


@inject('ShopStore')
@observer
class User extends Component {

  componentDidMount() {
    this.props.ShopStore.fetchShop()
  }

  render() {

    const { shop } = this.props.ShopStore

    return (
      <Button color='inherit'>
        {shop.name}
      </Button>
    )
  }
}

export default User