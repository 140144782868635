import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import ActivityTabbar from '../components/activity/ActivityTabbar'

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(12),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }
})

@withStyles(styles)
@inject('NavStore')
@observer
class Activity extends Component {

  componentDidMount() {
    this.props.NavStore.setActiveMenu('activity')
    this.props.NavStore.setNavBarTitle('Activity')
  }

  render() {

    const { classes, children } = this.props

    return (
      <Container maxWidth='xl' className={classes.container}>
        <ActivityTabbar />
          {children}
      </Container>
    )
  }
}

export default Activity