import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PopupForm from '../components/popup/PopupForm'
import Preview from '../components/popup/Preview'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  buttonGroup: {
    float: 'right',
  }
});


@withStyles(styles)
@inject('PopupStore', 'NavStore')
@observer
class Popup extends Component {

  handleSave = () => {
    this.props.PopupStore.update()
  }

  handleCancel = () => {
    this.props.PopupStore.initPayload()
  }

  componentDidMount() {
    this.props.NavStore.setActiveMenu('popup')
    this.props.NavStore.setNavBarTitle('Popup')
  }

  render() {
    const { classes } = this.props
    const { payloadChanged } = this.props.PopupStore
    console.log(payloadChanged)
    return (
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <div>
            <div className={classes.buttonGroup}>
              {payloadChanged 
                ? <Button style={{marginRight: '10px'}} onClick={this.handleCancel}>
                    Cancel
                  </Button>
                : ''
              }
              <Button disabled={!payloadChanged} variant='contained' color='primary' onClick={this.handleSave}>
                Save
              </Button>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={5} className={classes.widgetForm}>
              <PopupForm />
            </Grid>
            <Grid item xs={7}>
              <Preview />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}
export default Popup