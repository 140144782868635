import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import EarnRuleList from './EarnRuleList';
import EarnRuleUpdateModal from './EarnRuleUpdateModal'
import EarnRuleStatusModifyAlertDialog from './EarnRuleStatusModifyAlertDialog'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    display: 'inline'
  },
})

@withStyles(styles)
@inject('CommonStore')
@observer
class EarnSection extends Component {
  render() {
    const { classes } = this.props
    return (
      <div>
        <EarnRuleUpdateModal />
        <EarnRuleStatusModifyAlertDialog />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography  variant='h6' gutterBottom color='primary'>
                Earn Points
              </Typography>
              <br/>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                <Typography variant="body2" gutterBottom>
                  Manage the ways your customers earn points. 
                </Typography>
                <Typography variant="body2" gutterBottom>
                </Typography>
                </Grid>
                <Grid item xs={8}>
                  <EarnRuleList />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid> 
      </div>
    )
  }
}
export default EarnSection