import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/styles'
import { observer, inject } from 'mobx-react'
import { prizeTypeValue } from '../../constant';
import PrizeSummary from '../PrizeSummary';
import { priceFormat } from '../../utils/priceFormat';


const styles = theme => ({
  form: {
    '& .MuiTextField-root': {
      marginRight: theme.spacing(3),
    },
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
})

@withStyles(styles)
@inject('ReferralStore', 'CollectionStore', 'ShopStore')
@observer
class RewardRuleUpdateModal extends Component {

  handleClose = () => {
    this.props.ReferralStore.setUpdateModalClose()
  }

  handleSave = () => {
    this.props.ReferralStore.prizeRuleUpdateSubmit()
  }

  handleInputChange = e => {
    const name = e.target.name
    const value = name === 'prize_type' ? Number(e.target.value) : e.target.value
    this.props.ReferralStore.prizeRuleUpdateOnChange(name, value)
  }

  prizeSummary = () => {
    const { updatePrizeRulePayload, updatePrizeRuleType } = this.props.ReferralStore
    const obj = updatePrizeRuleType === 'customer' ? 'Customer' : 'Friend'
    let data = []
    if (updatePrizeRulePayload.prize_type === prizeTypeValue.point) {
      data.push(`${obj} gets ${updatePrizeRulePayload.prize_value} points.`)
      return data
    }
    if (updatePrizeRulePayload.prize_type === prizeTypeValue.percentage) {
      data.push(`${obj} gets ${updatePrizeRulePayload.prize_value}% OFF coupon.`)
    } else if (updatePrizeRulePayload.prize_type === prizeTypeValue.amount) {
      const priceValueFormat = priceFormat(updatePrizeRulePayload.prize_value, this.props.ShopStore.shop)
      data.push(`${obj} gets ${priceValueFormat} OFF coupon.`)
    }
    const apply_to = updatePrizeRulePayload.apply_to === 'all' ? 'all orders' : `collection id: ${updatePrizeRulePayload.apply_to_collection_id ? updatePrizeRulePayload.apply_to_collection_id : ''}`
    
    const minRequiredFormat = priceFormat(updatePrizeRulePayload.min_required, this.props.ShopStore.shop)

    const minRequired = updatePrizeRulePayload.min_required > 0 ? ` over ${minRequiredFormat}` : ''
    data.push(`Apply to ${apply_to}${minRequired}.`)
    
    if (updatePrizeRulePayload.discount_code_prefix !== '') {
      data.push(`Prefix: "${updatePrizeRulePayload.discount_code_prefix}".`)
    }
    return data
  }

  render() {

    const { classes } = this.props
    const { updatePrizeRuleModalOpen, updatePrizeRulePayload, updatePrizeRuleType } = this.props.ReferralStore
    const { collections } = this.props.CollectionStore
    return (
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={updatePrizeRuleModalOpen}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Update referral {updatePrizeRuleType} reward</DialogTitle>
        
        <DialogContent>
          <Grid container spacing={2}>
          <Grid item xs={7}>
          <Typography  variant='subtitle1' gutterBottom color='primary'>Prize type</Typography>
          <FormControl component="fieldset" hidden>
            <RadioGroup value={updatePrizeRulePayload.prize_type} onChange={this.handleInputChange}>
              <FormControlLabel name='prize_type' value={prizeTypeValue.percentage} control={<Radio size='small' color='primary' />} label="Percentage off" />
              <FormControlLabel name='prize_type' value={prizeTypeValue.amount} control={<Radio size='small' color='primary' />} label="Fixed amount off" />
              {
                updatePrizeRuleType === 'customer' ?
                <FormControlLabel name='prize_type' value={prizeTypeValue.point} control={<Radio size='small' color='primary' />} label="Points" />
                : ''
              }
            </RadioGroup>
          </FormControl>

          <Typography  variant='subtitle1' gutterBottom color='primary'>Prize Rule</Typography>
          <form className={classes.form}>
          <TextField
            label='Prize value' 
            margin="dense"
            type="number"
            value={updatePrizeRulePayload.prize_value}
            name='prize_value'
            onChange={this.handleInputChange}
            variant='outlined'
            fullWidth
          />
          </form>

          {
            updatePrizeRulePayload.prize_type === prizeTypeValue.point
              ? ''
              : <>
                  <Typography  variant='subtitle1' gutterBottom color='primary'>Apply to</Typography>
                  <RadioGroup value={updatePrizeRulePayload.apply_to} onChange={this.handleInputChange}>
                    <FormControlLabel name='apply_to' value="all" control={<Radio size='small' color='primary' />} label="All" />
                    <FormControlLabel name='apply_to' value="collection" control={<Radio size='small' color='primary' />} label="Specific collection" />
                  </RadioGroup>
                  {
                    updatePrizeRulePayload.apply_to === 'collection'
                      ? <TextField
                          id="demo-controlled-open-select"
                          fullWidth
                          size='small'
                          select
                          value={updatePrizeRulePayload.apply_to_collection_id}
                          name='apply_to_collection_id'
                          onChange={this.handleInputChange}
                          variant='outlined'
                        >
                          {collections.map(item => (
                            <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                          ))} 
                        </TextField>
                      : ''
                  }
        
                  <Typography  variant='subtitle1' gutterBottom color='primary'>Minimum required</Typography>
                  <TextField
                    // label=''
                    margin="dense"
                    fullWidth
                    type="number"
                    value={updatePrizeRulePayload.min_required}
                    name='min_required'
                    onChange={this.handleInputChange}
                    variant='outlined'
                  />
        
                  <Typography  variant='subtitle1' gutterBottom color='primary'>Discount code prefix</Typography>
                  <TextField
                    // label=''
                    margin="dense"
                    fullWidth
                    type="text"
                    value={updatePrizeRulePayload.discount_code_prefix}
                    name='discount_code_prefix'
                    onChange={this.handleInputChange}
                    variant='outlined'
                  />
                </>
          }
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle1' gutterBottom color='primary'>Summary</Typography>
            <PrizeSummary data={this.prizeSummary()} />
          </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="default">
            Close
          </Button>
          <Button onClick={this.handleSave} color="primary" variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default RewardRuleUpdateModal