import { observable, action, computed } from 'mobx'
import axios from '../services/axios'
import NotificationStore from './notification'


class EarnPointRuleStore {

  @observable earnPointRule = {}
  @observable updateModalOpen = false
  @observable updatePayload = {}
  @observable updateModalType = ''
  @observable statusModifyAlertOpen = false
  @observable aliasEditModalOpen = false

  @computed get updateModalTitle() {
    const titleTypeMap = {
      register: 'Register',
      birthday: 'Birthday',
      placeAnOrder: 'Place an Order'
    }
    return titleTypeMap[this.updateModalType]
  }

  @action setAliasEditModalOpen = () => {
    this.aliasEditModalOpen = true
  }

  @action setAliasEditModalClose = () => {
    this.aliasEditModalOpen = false
  }

  @action setStatusModifyAlertOpen = () => {
    this.statusModifyAlertOpen = true
  }
  
  @action setStatusModifyAlertClose = () => {
    this.statusModifyAlertOpen = false
  }

  @action setUpdateModalType = type => {
    this.updateModalType = type
  }

  @action clearUpdatePayload = () => {
    this.updatePayload = {}
  }

  @action initUpdatePayload = () => {
    let payload = {
      register_value: this.earnPointRule.register_value,
      birthday_value: this.earnPointRule.birthday_value,
      place_order_type: this.earnPointRule.place_order_type,
      place_order_value: this.earnPointRule.place_order_value,
      point_alias: this.earnPointRule.point_alias,
      point_status: this.earnPointRule.point_status
    }
    this.setUpdatePayload(payload)
  }

  @action update = () => {
    axios.put('/points_earn_rule', this.updatePayload)
      .then(res => {
        if (res.data.success === 1) {
          this.fetchEarnPointRule()
          this.setUpdateModalClose()
          NotificationStore.notify(
            'success',
            'info',
            'Points earn rule updated.'
          )
        }
      })
  }

  @action setUpdatePayload = payload => {
    this.updatePayload = payload
  }

  @action updatePayloadOnChange = (k, v) => {
    this.updatePayload[k] = v
  }

  @action setUpdateModalOpen = () => {
    this.updateModalOpen = true
  }

  @action setUpdateModalClose = () => {
    this.updateModalOpen = false
  }

  @action setEarnPointRule = data => {
    this.earnPointRule = data
  }

  @action fetchEarnPointRule = () => {
    axios.get('/points_earn_rule').then(res => {
      if (res.data.success === 1) {
        this.setEarnPointRule(res.data.data)
      }
    })
  }
}

export default new EarnPointRuleStore()