import React, { Component } from 'react'
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  depositContext: {
    flex: 1,
  },
})

@withStyles(styles)
class Overview extends Component {

  handleClick = () => {
    this.props.onClick()
  }

  render() {

    const { classes } = this.props
    const { title, data, to, viewText, textSecondary } = this.props

    return (
      <React.Fragment>
        <Typography component="h2" variant="h6" color='textSecondary' gutterBottom>
          {title}
        </Typography>
        <Typography component="p" variant="h4">
          {data}
        </Typography>
        <Typography color="textSecondary" className={classes.depositContext}>
          {textSecondary}
        </Typography>
        <div>
          <Link color="primary" component={RouterLink} onClick={this.handleClick} to={to}>
            {viewText}
          </Link>
        </div>
      </React.Fragment>
    )
  }
}

export default Overview