import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import { observer, inject } from 'mobx-react'


@inject('EarnPointRuleStore')
@observer
class PointAliasEditModal extends Component {

  handleSave = () => {
    this.props.EarnPointRuleStore.update()
    this.props.EarnPointRuleStore.fetchEarnPointRule()
    this.props.EarnPointRuleStore.setAliasEditModalClose()
  }

  handleClose = () => {
    this.props.EarnPointRuleStore.setAliasEditModalClose()
  }

  handlePointAliasChange = e => {
    this.props.EarnPointRuleStore.updatePayloadOnChange('point_alias', e.target.value)
  }

  render() {

    const { aliasEditModalOpen, updatePayload } = this.props.EarnPointRuleStore
    
    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={aliasEditModalOpen}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Points Alias</DialogTitle>
        <DialogContent>
          
          {/* <Typography  variant='subtitle1' gutterBottom color='primary'>Alias</Typography> */}
          <TextField
            autoFocus
            margin="dense"
            type="text"
            value={updatePayload.point_alias}
            onChange={this.handlePointAliasChange}
            fullWidth
          />
          <DialogContentText>
            {/* {this.summary()} */}
            You've earned 200 {updatePayload.point_alias}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="default">
            Close
          </Button>
          <Button onClick={this.handleSave} color="primary" variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default PointAliasEditModal