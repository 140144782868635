import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { observer, inject } from 'mobx-react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';
import { ChromePicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AlarmIcon from '@material-ui/icons/Alarm';


const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex'
  },
  label: {
    marginTop: '5px',
    display: 'inline-flex',
    width: '100%',
    cursor: 'pointer'
  },
  color: {
    width: '40%',
    height: '32px',
    display: 'flex',
    marginRight: '10px',
    borderRadius: '4px',
  },
  hex: {
    width: '40%',
    height: '32px',
    lineHeight: '32px',
    display: 'flex'
  },
  colorPicker: {
    position: 'absolute',
    zIndex: 5
  }
})


@withStyles(styles)
@inject('PopupStore')
@observer
class PopupForm extends Component {

  handleChange = e => {
    this.props.PopupStore.payloadOnChange(e.target.name, e.target.value)
  }

  handleThemeColorChange = color => {
    this.props.PopupStore.payloadOnChange('theme_color', color.hex)
  }

  handleFontColorChange = color => {
    this.props.PopupStore.payloadOnChange('font_color', color.hex)
  }

  handleThemeColorPickerToggle = () => {
    this.props.PopupStore.setThemeColorPickerToggle()
  }

  handleFontColorPickerToggle = () => {
    this.props.PopupStore.setFontColorPickerToggle()
  }

  componentWillMount() {
    this.props.PopupStore.fetchData()
  }

  handleCheckChange = e => {
    const k = e.target.name
    const v = e.target.checked ? 1 : 0
    this.props.PopupStore.payloadOnChange(k, v)
  }

  delayHelperText = delay => {
    if (delay > 0) {
      return 'seconds after entering.'
    } else {
      return 'immediately.'
    }
  }

  colorStyle = backgroundColor => {
    let style = {
      background: backgroundColor
    }
    if (backgroundColor === '#ffffff') {
      style['border'] = '1px solid #f4f4f4'
    }

    return style
  }

  render() {
    const {
      payload,
      themeColorPickerToggle,
      fontColorPickerToggle
    } = this.props.PopupStore
    const { classes } = this.props
    return (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography  variant='subtitle1' color='primary'>Theme Color</Typography>
            <div className={classes.label} onClick={this.handleThemeColorPickerToggle}>
              <span className={classes.color} style={this.colorStyle(payload.theme_color)} />
              <span className={classes.hex}>
                {payload.theme_color}
              </span>
            </div>
            {
              themeColorPickerToggle 
                ?
                <ClickAwayListener onClickAway={this.handleThemeColorPickerToggle}>
                  <ChromePicker 
                    className={classes.colorPicker} 
                    color={payload.theme_color}
                    onChange={this.handleThemeColorChange}
                  />
                </ClickAwayListener>
                : ''
            }
          </Grid>
          <Grid item xs={6}>
            <Typography  variant='subtitle1' color='primary'>Font Color</Typography>
            <div className={classes.label} onClick={this.handleFontColorPickerToggle}>
              <span className={classes.color} style={this.colorStyle(payload.font_color)} />
              <span className={classes.hex}>
                {payload.font_color}
              </span>
            </div>
            {
              fontColorPickerToggle 
                ?
                <ClickAwayListener onClickAway={this.handleFontColorPickerToggle}>
                  <ChromePicker 
                    className={classes.colorPicker} 
                    color={payload.font_color}
                    onChange={this.handleFontColorChange}
                  />
                </ClickAwayListener>
                : ''
            }
          </Grid>
        </Grid>
        <Typography  variant='subtitle1' color='primary'>Popup display</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payload.home_switch === 1}
                    onChange={this.handleCheckChange}
                    name='home_switch'
                    color="primary"
                  />
                }
                label="Home page"
              />
            </Grid>
            <Grid item xs={6}>
              {
                payload.home_switch === 1
                  ? <TextField 
                      variant='outlined'
                      type='number'
                      size='small'
                      helperText={this.delayHelperText(payload.home_delay)}
                      fullWidth
                      name='home_delay'
                      value={payload.home_delay}
                      onChange={this.handleChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <AlarmIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payload.collection_switch === 1}
                    onChange={this.handleCheckChange}
                    name='collection_switch'
                    color="primary"
                  />
                }
                label="Collection page"
              />
            </Grid>
            <Grid item xs={6}>
              {
                payload.collection_switch === 1
                  ? <TextField 
                      variant='outlined'
                      type='number'
                      size='small'
                      helperText={this.delayHelperText(payload.collection_delay)}
                      fullWidth
                      name='collection_delay'
                      value={payload.collection_delay}
                      onChange={this.handleChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <AlarmIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payload.product_switch === 1}
                    onChange={this.handleCheckChange}
                    name="product_switch"
                    color="primary"
                  />
                }
                label="Product page"
              />
            </Grid>
            <Grid item xs={6}>
              {
                payload.product_switch === 1
                  ? <TextField 
                      variant='outlined'
                      type='number'
                      size='small'
                      helperText={this.delayHelperText(payload.product_delay)}
                      fullWidth
                      name='product_delay'
                      value={payload.product_delay}
                      onChange={this.handleChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <AlarmIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payload.checkout_switch === 1}
                    onChange={this.handleCheckChange}
                    name="checkout_switch"
                    color="primary"
                  />
                }
                label="Checkout page"
              />
            </Grid>
            <Grid item xs={6}>
              {
                payload.checkout_switch === 1
                  ? <TextField 
                      type='number'
                      variant='outlined'
                      size='small'
                      helperText={this.delayHelperText(payload.checkout_delay)}
                      fullWidth
                      name='checkout_delay'
                      value={payload.checkout_delay}
                      onChange={this.handleChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <AlarmIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  : ''
              }
              
            </Grid>
          </Grid>
      </Container>
    )
  }
}
export default PopupForm