import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import SettingSwitchForm from './SettingSwitchForm';
import PointAliasItem from './PointAliasItem'

const styles = theme => ({ paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    display: 'inline'
  },
})

@withStyles(styles)
@inject('CommonStore', 'EarnPointRuleStore')
@observer
class Setting extends Component {
  render() {
    const { classes } = this.props

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography  variant="h6" gutterBottom color='primary'>
              Points Setting
            </Typography>
            <br />
            <Grid container spacing={4}>
              <Grid item xs={4}>
              <Typography variant='subtitle1' color='primary'>
                Points Alias
              </Typography>
              <Typography variant="body2" gutterBottom>
                Name your points currency to match your brand. 
              </Typography>
              </Grid>
              <Grid item xs={8}>
                <PointAliasItem />
              </Grid>
              
              <Grid item xs={4}>
              <Typography variant='subtitle1' color='primary'>
                Points Status
              </Typography>
              <Typography variant="body2" gutterBottom>
                Enable or disable your points program.
              </Typography>
              </Grid>
              <Grid item xs={8}>
                <SettingSwitchForm />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid> 
    )
  }
}
export default Setting