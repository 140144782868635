import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

@inject('NotificationStore')
@observer
class Notification extends Component {
  
  handleClose = () => {
    this.props.NotificationStore.setClose()
  }

  render() {
    const { open, severity, color, message } = this.props.NotificationStore
    return (
      <Snackbar 
        open={open} 
        autoHideDuration={2000} 
        onClose={this.handleClose}
        anchorOrigin={{ 
          vertical: 'top', 
          horizontal: 'right'
       }}
      >
        <Alert 
          onClose={this.handleClose} 
          severity={severity}
          color={color}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }
}

export default Notification;