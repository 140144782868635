import React, { Component } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import CustomerListItem from './CustomerListItem';


@inject('CustomerStore')
@observer
class CustomerList extends Component {
  render() {

    const { data } = this.props.CustomerStore

    return (
      <>
      <Table size='medium'>
        <TableHead>
            <TableRow>
              <TableCell>
                {/* <TableSortLabel
                  active={campaignQuery.orderBy === 'id'}
                  direction={campaignQuery.orderBy === 'id' ? campaignQuery.order: 'desc'}
                  onClick={(e) => this.handleSortClick('id', e)}
                >
                  Id
                </TableSortLabel> */}
                Name
              </TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Orders</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Referrals</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

          {data.map((item, i) => (
            <CustomerListItem item={item} key={item.id} /> 
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
}

export default CustomerList;