import React, { Component } from 'react'
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  picker: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    display: 'flex',
    width: 200
  },
  to: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
})

@withStyles(styles)
class DateRangePicker extends Component {

  handleBeginChange = (date) => {
    const [, end] = this.props.value
    let value = [date, end]
    this.props.onChange(value)
  }

  handleEndChange = date => {
    const [begin,] = this.props.value
    let value = [begin, date]
    this.props.onChange(value)
  }

  render() {
    const {
      value,
    } = this.props
    const [begin, end] = value
    const { classes } = this.props

    return (
      <div className={classes.picker}>
        <span className={classes.input}>
          <KeyboardDatePicker
            label='Begin'
            size='small'
            value={begin}
            variant='inline'
            inputVariant='outlined'
            orientation="landscape"
            onChange={this.handleBeginChange}
            format="yyyy/MM/dd"
          />
        </span>
        <span className={classes.to}>
          to 
        </span>
        <span className={classes.input}>
          <KeyboardDatePicker
            label='End'
            size='small'
            value={end}
            variant='inline'
            inputVariant='outlined'
            orientation="landscape"
            onChange={this.handleEndChange}
            format="yyyy/MM/dd"
          />
        </span>
      </div>
    )
  }
}

export default DateRangePicker