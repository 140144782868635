import { observable, action, computed } from 'mobx'
import axios from '../services/axios'
import moment from 'moment'


class ReferralReportStore {

  @observable data = []

  @observable dateRangeBegin = moment().subtract(29, 'days')
  @observable dateRangeEnd = moment()
  @observable groupBy = 'date'
  @observable order = 'desc'
  @observable count = 0
  @observable timezone = moment().format('Z')
  @observable limit = 30
  @observable offset = 0

  @computed get begin() {
    return moment(this.dateRangeBegin).startOf('day').valueOf()
  }

  @computed get end() {
    return moment(this.dateRangeEnd).add(1, 'days').startOf('day').valueOf()
  }

  @computed get page() {
    return Math.floor(this.offset / this.limit)
  }

  @action setLimit = limit => {
    this.limit = limit
  }

  @action setOffset = offset => {
    this.offset = offset
  }

  @action handleChangeRowsPerPage = value => {
    this.setLimit(value)
    this.fetchData()
  }

  @action handlePageChange = page => {
    this.setOffset(this.limit * page)
    this.fetchData()
  }

  @action setGroupBy = groupBy => {
    this.groupBy = groupBy
  }

  @action dateRangeOnChange = (begin, end) => {
    this.dateRangeBegin = begin
    this.dateRangeEnd = end
  }

  @computed get api() {
    return `/report/referral?begin=${this.begin}&end=${this.end}&group_by=${this.groupBy}&order=${this.order}&timezone=${encodeURIComponent(this.timezone)}&limit=${this.limit}&offset=${this.offset}`
  }

  @action fetchData = () => {
    axios.get(this.api).then(res => {
      if (res.data.success === 1) {
        this.setData(res.data.data.result)
        this.setCount(res.data.data.total_count)
      }
    })
  }

  @action setData = data => {
    this.data = []
    this.data.push(...data)
  }

  @action setCount = count => {
    this.count = count
  }
}

export default new ReferralReportStore()