import { observable, action, computed } from 'mobx'
import axios from '../services/axios'
import NotificationStore from './notification'


class PopupStore {
  
  @observable data = {}
  @observable payload = {}
  @observable themeColorPickerToggle = false
  @observable fontColorPickerToggle = false

  @computed get payloadChanged() {
    const keys = [
      'home_switch',
      'home_delay',
      'collection_switch',
      'collection_delay',
      'product_switch',
      'product_delay',
      'checkout_switch',
      'checkout_delay',
      'theme_color',
      'font_color',
    ] 
    let payloadStr = ''
    let dataStr = ''
    keys.map(item => {
      payloadStr += String(this.payload[item])
      dataStr += String(this.data[item])
      return 1
    })
    return payloadStr !== dataStr
  }

  @action setThemeColorPickerToggle = () => {
    this.themeColorPickerToggle = !this.themeColorPickerToggle
  }

  @action setFontColorPickerToggle = () => {
    this.fontColorPickerToggle = !this.fontColorPickerToggle
  }

  @action setData = data => {
    this.data = data
  }

  @action initPayload = () => {
    this.payload = Object.assign({}, this.data)
  }

  @action fetchData = () => {
    axios.get('/popup').then(res => {
      if (res.data.success === 1) {
        this.setData(res.data.data)
        this.initPayload()
      }
    })
  }

  @action update = () => {
    axios.put('/popup', this.payload).then(res => {
      if (res.data.success === 1) {
        this.fetchData()
        NotificationStore.notify(
          'success',
          'info',
          'Popup settings saved.'
        )
      }
    })
  }

  @action payloadOnChange = (k, v) => {
    this.payload[k] = v
  }
  
}

export default new PopupStore()