import React, { Component } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles'
import LandingPageUpdateModal from './LandingPageUpdateModal'


const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    paddingTop: '0px',
  },
  inline: {
    display: 'inline',
  },
  listItem: {
    paddingTop: '0px'
  }
})

@withStyles(styles)
@inject('ReferralStore')
@observer
class LandingPageItem extends Component {

  handleEditClick = () => {
    this.props.ReferralStore.initUpdateSettingPayload()
    this.props.ReferralStore.setUpdateLandingPageModalOpen()
  }

  render() {
    const { classes } = this.props
    const { referralSetting } = this.props.ReferralStore

    return (
      <List className={classes.root}>
        <LandingPageUpdateModal />
        <ListItem className={classes.paddingTop}>
          <ListItemText
            primary={referralSetting.landing_page}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  All referred visit will be sent to {referralSetting.landing_page}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            <Button size='small' onClick={this.handleEditClick} color='primary' variant='contained'>
              Edit
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </List>
    )
  }
}
export default LandingPageItem