import React, { Component } from 'react'
import RevenueChart from './revenue/RevenueChart';
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import RevenueTable from './revenue/RevenueTable'
import RevenueFilter from './revenue/RevenueFilter'


@inject('ReportTabStore', 'RevenueReportStore')
@observer
class RevenueReport extends Component {

  componentDidMount() {
    if (this.props.RevenueReportStore.data.length === 0) {
      this.props.RevenueReportStore.fetchData()
    }
  }

  render() {

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RevenueFilter />
        </Grid>
        <Grid item xs={12}>
          <RevenueChart />
        </Grid>
        <Grid item xs={12}>
          <RevenueTable />
        </Grid>
      </Grid>
    )
  }
}
export default RevenueReport