import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { inject, observer } from 'mobx-react'

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
})

@withStyles(styles)
@inject('ShopStore', 'TutorialStore')
@observer
class Tutorial extends Component {

  handleCustomerEnable = () => {
    window.open(`https://${this.props.ShopStore.shop.myshopify_domain}/admin/settings/checkout`, '_blank') 
    this.props.TutorialStore.setNext()
  }

  handleCustomizeWidget = () => {
    window.open('/widget', '_blank')
    this.props.TutorialStore.setNext()
  }

  handleCustomizePopup = () => {
    window.open('/popup', '_blank')
    this.props.TutorialStore.setNext()
  }

  handlePointsSetting = () => {
    window.open('/point', '_blank')
    this.props.TutorialStore.setNext()
  }

  handleReferralSetting = () => {
    window.open('/referral', '_blank')
    this.props.TutorialStore.setNext()
  }

  handleTutorialFinished = () => {
    this.props.ShopStore.payloadOnChange('tutorial_passed', 1)
    this.props.ShopStore.update()
  }

  handleResetStep = () => {
    this.props.TutorialStore.setStep(0)
  }

  handleBack = () => {
    this.props.TutorialStore.setBack()
  }

  render() {
    const { classes } = this.props
    const { currentStep, totalStep } = this.props.TutorialStore

    return (
      <div className={classes.root}>
        <Stepper activeStep={currentStep} orientation="vertical">
          
          <Step key='enable_customer'>
            <StepLabel>Enable Customer Checkout</StepLabel>
            <StepContent>
              <Typography>To allow members to join your program, customer accounts must be optional or required on your Shopify Store.</Typography>
              <Typography>Your customers will not receive points or rewards until they login to the shop.</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleCustomerEnable}
                    className={classes.button}
                  >
                    Enable
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
          
          <Step key='customize_widget'>
            <StepLabel>Customize Widget</StepLabel>
            <StepContent>
              <>
                <Typography>Customize the theme color & font color of the widget.</Typography>
              </>
              <div className={classes.actionsContainer}>
                <div>
                  <Button className={classes.button} onClick={this.handleBack}>Back</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleCustomizeWidget}
                    className={classes.button}
                  >
                    Customize
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
          
          <Step key='customize_popup'>
            <StepLabel>Customize Popup</StepLabel>
            <StepContent>
              <>
                <Typography>Customize the theme color & font color of the popup.</Typography>
              </>
              <div className={classes.actionsContainer}>
                <div>
                  <Button className={classes.button} onClick={this.handleBack}>Back</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleCustomizePopup}
                    className={classes.button}
                  >
                    Customize
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

          <Step key='points_setting'>
            <StepLabel>Points Setting</StepLabel>
            <StepContent>
              <>
                <Typography>Customize the rules to earn & redeem points.</Typography>
              </>
              <div className={classes.actionsContainer}>
                <div>
                  <Button className={classes.button} onClick={this.handleBack}>Back</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handlePointsSetting}
                    className={classes.button}
                  >
                    Customize
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

          <Step key='referral_setting'>
            <StepLabel>Referral Setting</StepLabel>
            <StepContent>
              <Typography>Customize the rules of referral reward.</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button className={classes.button} onClick={this.handleBack}>Back</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleReferralSetting}
                    className={classes.button}
                  >
                    Customize
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

        </Stepper>
        {currentStep === totalStep && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re ready to go!</Typography>
            <Button 
              variant="contained"
              color="primary"
              onClick={this.handleTutorialFinished} 
              className={classes.button}
            >
              Start
            </Button>
          </Paper>
        )}
      </div>
    );
  }
}

export default Tutorial;