import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch'
import { observer, inject } from 'mobx-react'


@inject('ReferralStore')
@observer
class StatusItem extends Component {

  handleChange = e => {
    const value = e.target.checked === true ? 1 : 0
    this.props.ReferralStore.updateSettingPayloadOnChange(e.target.name, value)
    this.props.ReferralStore.updateSetting()
  }

  render() {
    const { referralSetting } = this.props.ReferralStore

    return (
      <div>
        <Switch
          color='primary'
          checked={referralSetting.switch === 1 ? true : false}
          onChange={this.handleChange}
          name="switch"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    )
  }
}
export default StatusItem