import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  "palette": {
    type: 'light',
    // "common": {
    //     "black": "#000",
    //     "white": "#fff"
    // },
    "background": {
        // "paper": "#2a2d3d",
        "default": "#f2f6fa"
    },
    // "primary": {
    //     // "light": "rgba(229, 115, 115, 1)",
    //     "main": "#a67dff",
    //     // "dark": "rgba(211, 47, 47, 1)",
    //     // "contrastText": "#fff"
    // },
    // "secondary": {
    //     "light": "rgba(0, 211, 175, 1)",
    //     "main": "#311b92",
    //     "dark": "rgba(0, 165, 136, 1)",
    //     "contrastText": "#fff"
    // },
    // "error": {
    //     "light": "#e57373",
    //     "main": "#f44336",
    //     "dark": "#d32f2f",
    //     "contrastText": "#fff"
    // },
    // // "text": {
    // //     "primary": "rgba(255, 255, 255, 1)",
    // //     "secondary": "rgba(202, 202, 202, 1)",
    // //     "disabled": "rgba(168, 168, 168, 1)",
    // //     "hint": "rgba(0, 0, 0, 0.38)"
    // // },
    // "text": {
    //     "primary": "rgba(255, 255, 255, 1)",
    //     "secondary": "rgba(181, 181, 181, 1)",
    //     "disabled": "rgba(181, 181, 181, 1)",
    //     "hint": "rgba(0, 0, 0, 0.38)"
    // }
},
});

export default theme;