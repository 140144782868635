import { observable, action } from 'mobx'

class ReportTabStore {

  @observable route = 'revenue'

  @action setRoute = route => {
    this.route = route
  }
}

export default new ReportTabStore()