import { observable, action, computed, autorun } from 'mobx'
import { prizeTypeValue } from '../constant'
import axios from '../services/axios'
import NotificationStore from './notification'
import { rewardPrizeTypeValueMapping } from '../constant'


class RedeemPointRuleStore {

  @observable rules = []
  @observable pageSize = 3
  @observable page = 1
  @observable count = 0
  @observable createModalOpen = false
  @observable createPayload = {}
  @observable updateModalOpen = false
  @observable updatePayload = {}
  @observable statusModifyAlertOpen = false
  @observable itemTogglePayload = {}

  @action update = () => {
    axios.put('/points_redeem_rule', this.updatePayload).then(res => {
      if (res.data.success === 1) {
        this.fetchRules()
        this.setUpdateModalClose()
        NotificationStore.notify(
          'success',
          'info',
          'Points redeem rule updated.'
        )
      }
    })
  }

  @action enable = item => {
    this.itemTogglePayload = {
      switch: 1,
      shop_points_redeem_rule_id: item.shop_points_redeem_rule_id
    }
  }

  @action disable = item => {
    this.itemTogglePayload = {
      switch: 0,
      shop_points_redeem_rule_id: item.shop_points_redeem_rule_id
    }
  }

  @action itemToggleConfirm = () => {
    axios.post('/points_redeem_rule_toggle', this.itemTogglePayload).then(res => {
      if (res.data.success === 1) {
        this.fetchRules()
        NotificationStore.notify(
          'success',
          'info',
          `Points redeem rule ${this.itemTogglePayload.switch === 1 ? 'enabled' : 'disabled'}.`
        )
      }
    })
  }

  @action clearUpdatePayload = () => {
    this.updatePayload = {}
  }

  @action setStatusModifyAlertOpen = () => {
    this.statusModifyAlertOpen = true
  }

  @action setStatusModifyAlertClose = () => {
    this.statusModifyAlertOpen = false
  }

  @action setUpdateModalClose = () => {
    this.updateModalOpen = false
  }

  @action setUpdateModalOpen = () => {
    this.updateModalOpen = true
  }

  @action initUpdatePayload = item => {
    for (const [k, v] of Object.entries(item)) {
      this.updatePayload[k] = v
    }
  }

  @action updatePayloadOnChange = (k, v) => {
    this.updatePayload[k] = v
  }

  @action create = () => {
    axios.post('/points_redeem_rule', this.createPayload).then(res => {
      if (res.data.success === 1) {
        this.fetchRules()
        this.setCreateModalClose()
        NotificationStore.notify(
          'success',
          'info',
          'New points redeem rule created.'
        )
      }
    })
  }

  @action initCreatePayload = () => {
    this.createPayload = {
      prize_type: prizeTypeValue.percentage,
      prize_value: 0,
      points: 0,
      apply_to: 'all',
      apply_to_collection_id: '',
      min_required: 0,
      discount_code_prefix: '',
    }
  }

  @action createPayloadOnChange = (k, v) => {
    this.createPayload[k] = v
  }

  createPayloadPrefix = autorun(() => {
    const prefix = `REDEEM-${this.createPayload.prize_value}${this.createPayload.prize_type === rewardPrizeTypeValueMapping.percentage ? '%' : ''}OFF`
    this.createPayloadOnChange('discount_code_prefix', prefix)
  })

  updatePayloadPrefix = autorun(() => {
    const prefix = `REDEEM-${this.updatePayload.prize_value}${this.updatePayload.prize_type === rewardPrizeTypeValueMapping.percentage ? '%' : ''}OFF`
    this.updatePayloadOnChange('discount_code_prefix', prefix)
  })

  @action setCreateModalClose = () => {
    this.createModalOpen = false
  }

  @action setCreateModalOpen = () => {
    this.createModalOpen = true
  }

  @action setRules = data => {
    this.rules = []
    this.rules.push(...data)
  }

  @action setCount = count => {
    this.count = count
  }

  @action setPage = page => {
    this.page = page
  }

  @computed get totalPage() {
    const p = this.count / this.pageSize
    return Math.ceil(p)
  }

  @computed get listApi() {
    return `/points_redeem_rule?limit=${this.pageSize}&offset=${(this.page - 1) * this.pageSize}` 
  }

  @action fetchRules = () => {
    axios.get(this.listApi)
      .then(res => {
        if (res.data.success === 1) {
          this.setRules(res.data.data)
          if (res.data.data.length > 0) {
            this.setCount(res.data.data[0].count)
          } else {
            this.setCount(0)
          }
        }
      })
  }
}

export default new RedeemPointRuleStore()