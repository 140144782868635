import { Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import React, { Component } from 'react'
import CustomerPaginator from './CustomerPaginator'
import { inject, observer } from 'mobx-react'

@inject('CustomerReportStore')
@observer
class CustomerTable extends Component {

  datetimeFormat = datetime => {
    if (datetime.length === 13) {
      return `${datetime}:00`
    } else {
      return datetime
    }
  }

  render() {

    const { data } = this.props.CustomerReportStore

    return (
      <Paper>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                {/* <TableSortLabel
                  active={campaignQuery.orderBy === 'id'}
                  direction={campaignQuery.orderBy === 'id' ? campaignQuery.order: 'desc'}
                  onClick={(e) => this.handleSortClick('id', e)}
                >
                  Id
                </TableSortLabel> */}
                Date
              </TableCell>
              <TableCell align='right'>
                Customers
              </TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>

          {data.map((item, i) => (
            <TableRow key={i}>
            <TableCell component="th" scope="row">
              {this.datetimeFormat(item.datetime)}
            </TableCell>
            <TableCell align='right'>{item.customers}</TableCell>
          </TableRow>
          ))}
          </TableBody>
          

        </Table>
        <CustomerPaginator />
      </Paper>
    )
  }
}
export default CustomerTable