import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles'
import Rewards from '../components/referral/Rewards';
import Setting from '../components/referral/Setting';


const styles = theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  }
})


@withStyles(styles)
@inject('ReferralStore', 'NavStore')
@observer
class Referrals extends Component {

  componentDidMount() {
    this.props.NavStore.setActiveMenu('referral')
    this.props.NavStore.setNavBarTitle('Referral')
    this.props.ReferralStore.fetchData()
  }

  render() {
    const { classes } = this.props
    return (
      <Container maxWidth='xl' className={classes.container}>
        <Rewards />
        {/* <GuestRule /> */}
        <Setting />
      </Container>
    )
  }
}
export default Referrals