import { observable, action, computed } from 'mobx'
import axios from '../services/axios'
import moment from 'moment'

class HomeStore {

  @observable begin = moment().subtract(6, 'days').startOf('day').valueOf()
  @observable end = moment().add(1, 'days').startOf('day').valueOf()
  @observable timezone = moment().format('Z')
  @observable customerOverview = 0
  @observable visitOverview = 0
  @observable revenueOverview = 0
  @observable rewardOverview = 0
  @observable prizeType = '1,2'
  @observable revenueChart = []
  @observable visitChart = []
  @observable pointsEarningActivity = []
  @observable rewardsRedeemActivity = []

  @computed get pointsEarningActivityApi() {
    return `/reward?limit=5&offset=0&order=desc&cost_type=&prize_type=3`
  }

  @computed get rewardsRedeemActivityApi() {
    return `/reward?limit=5&offset=0&order=desc&cost_type=4,5&prize_type=1,2`
  }

  @computed get customerOverviewApi() {
    return `/report/customer?begin=${this.begin}&end=${this.end}&limit=1&offset=0`
  }

  @computed get visitOverviewApi() {
    return `/report/referral?begin=${this.begin}&end=${this.end}&group_by=&limit=1&offset=0`
  }

  @computed get revenueOverviewApi() {
    return `/report/revenue?begin=${this.begin}&end=${this.end}&limit=1&offset=0`
  }

  @computed get rewardOverviewApi() {
    return `/reward/count?begin=${this.begin}&end=${this.end}&prize_type=${this.prizeType}`
  }

  @computed get revenueChartApi() {
    return `/report/revenue?begin=${this.begin}&end=${this.end}&group_by=date&limit=30&offset=0&timezone=${encodeURIComponent(this.timezone)}&order=asc`
  }

  @computed get visitChartApi() {
    return `/report/referral?begin=${this.begin}&end=${this.end}&group_by=date&limit=30&offset=0&timezone=${encodeURIComponent(this.timezone)}&order=asc`
  }

  @action fetchPointsEarningActivity = () => {
    axios.get(this.pointsEarningActivityApi).then(res => {
      if (res.data.success === 1) {
        this.pointsEarningActivity = []
        this.pointsEarningActivity.push(...res.data.data.result)
      }
    })
  }

  @action fetchRewardsRedeemActivity = () => {
    axios.get(this.rewardsRedeemActivityApi).then(res => {
      if (res.data.success === 1) {
        this.rewardsRedeemActivity = []
        this.rewardsRedeemActivity.push(...res.data.data.result)
      }
    })
  }

  @action fetchRevenueChart = () => {
    axios.get(this.revenueChartApi).then(res => {
      if (res.data.success === 1) {
        let { result } = res.data.data
        result.map(item => {
          item['revenue'] = (item.referral_customer_revenue + item.points_redeem_revenue + item.referral_friend_revenue).toFixed(2)
          return 1
        })
        this.revenueChart = []
        this.revenueChart.push(...result)
      }
    })
  }

  @action fetchVisitChart = () => {
    axios.get(this.visitChartApi).then(res => {
      if (res.data.success === 1) {
        this.visitChart = []
        this.visitChart.push(...res.data.data.result)
      }
    })
  }

  @action fetchRewardOverview = () => {
    axios.get(this.rewardOverviewApi).then(res => {
      if (res.data.success === 1) {
        this.rewardOverview = res.data.data.count
      }
    })
  }

  @action fetchRevenueOverview = () => {
    axios.get(this.revenueOverviewApi).then(res => {
      if (res.data.success === 1) {
        const { referral_customer_revenue, points_redeem_revenue, referral_friend_revenue } = res.data.data.result[0]
        this.revenueOverview = (referral_customer_revenue + points_redeem_revenue + referral_friend_revenue).toFixed(2)
      }
    })
  }

  @action fetchCustomerOverview = () => {
    axios.get(this.customerOverviewApi).then(res => {
      if (res.data.success === 1) {
        this.customerOverview = res.data.data.result[0].customers
      }
    })
  }

  @action fetchVisitOverview = () => {
    axios.get(this.visitOverviewApi).then(res => {
      if (res.data.success === 1) {
        this.visitOverview = res.data.data.result[0].click_count
      }
    })
  }
}

export default new HomeStore()