import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { observer, inject } from 'mobx-react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Heart from '../icons/Heart'
import Crown from '../icons/Crown'
import Gift from '../icons/Gift'
import Star from '../icons/Star'
import TagHeart from '../icons/TagHeart'
import { ChromePicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { widgetIconLayout, widgetPosition, widgetIconId } from '../../constant'


const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex'
  },
  label: {
    marginTop: '5px',
    display: 'inline-flex',
    width: '100%',
    cursor: 'pointer'
  },
  color: {
    width: '40%',
    height: '32px',
    display: 'flex',
    marginRight: '10px',
    borderRadius: '4px',
  },
  hex: {
    width: '40%',
    height: '32px',
    lineHeight: '32px',
    display: 'flex'
  },
  colorPicker: {
    position: 'absolute',
    zIndex: 5
  }
})

@withStyles(styles)
@inject('WidgetStore')
@observer
class WidgetForm extends Component {

  handleChange = e => {
    this.props.WidgetStore.payloadOnChange(e.target.name, e.target.value)
  }

  handleThemeColorChange = color => {
    this.props.WidgetStore.payloadOnChange('theme_color', color.hex)
  }

  handleFontColorChange = color => {
    this.props.WidgetStore.payloadOnChange('font_color', color.hex)
  }

  handleThemeColorPickerToggle = () => {
    this.props.WidgetStore.setThemeColorPickerToggle()
  }

  handleFontColorPickerToggle = () => {
    this.props.WidgetStore.setFontColorPickerToggle()
  }

  componentWillMount() {
    this.props.WidgetStore.fetchData()
  }

  handleIconClick = id => {
    this.props.WidgetStore.payloadOnChange('material_id', id)
  }

  colorStyle = backgroundColor => {
    let style = {
      background: backgroundColor
    }
    if (backgroundColor === '#ffffff') {
      style['border'] = '1px solid #f4f4f4'
    }

    return style
  }

  render() {

    const { payload, themeColorPickerToggle, fontColorPickerToggle } = this.props.WidgetStore
    const { classes } = this.props
    const { crown, gift, heart, star, tag } = widgetIconId

    return (
      <Container>
        <Typography  variant='subtitle1' color='primary'>Program Brand</Typography>
        <TextField
          margin="dense"
          type="text"
          value={payload.program_name}
          defaultValue={payload.program_name}
          name='program_name'
          onChange={this.handleChange}
          variant='outlined'
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography  variant='subtitle1' color='primary'>Theme Color</Typography>
            <div className={classes.label} onClick={this.handleThemeColorPickerToggle}>
              <span className={classes.color} style={this.colorStyle(payload.theme_color)} />
              <span className={classes.hex}>
                {payload.theme_color}
              </span>
            </div>
            {
              themeColorPickerToggle 
                ?
                <ClickAwayListener onClickAway={this.handleThemeColorPickerToggle}>
                  <ChromePicker 
                    className={classes.colorPicker} 
                    color={payload.theme_color}
                    onChange={this.handleThemeColorChange}
                  />
                </ClickAwayListener>
                : ''
            }
          </Grid>
          <Grid item xs={6}>
            <Typography  variant='subtitle1' color='primary'>Font Color</Typography>
            <div className={classes.label} onClick={this.handleFontColorPickerToggle}>
              <span className={classes.color} style={this.colorStyle(payload.font_color)} />
              <span className={classes.hex}>
                {payload.font_color}
              </span>
            </div>
            {
              fontColorPickerToggle 
                ?
                <ClickAwayListener onClickAway={this.handleFontColorPickerToggle}>
                  <ChromePicker 
                    className={classes.colorPicker} 
                    color={payload.font_color}
                    onChange={this.handleFontColorChange}
                  />
                </ClickAwayListener>
                : ''
            }
          </Grid>
        </Grid>
        <Typography variant='subtitle1' color='primary'>Widget Layout</Typography>
        <FormControl component="fieldset" hidden>
          <RadioGroup value={payload.launcher_desktop_layout || ''} onChange={this.handleChange}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel name='launcher_desktop_layout' value={widgetIconLayout.iconWithText} control={<Radio size='small' color='primary' />} label="Icon with Content" />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel name='launcher_desktop_layout' value={widgetIconLayout.icon} control={<Radio size='small' color='primary' />} label="Icon only" />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel name='launcher_desktop_layout' value={widgetIconLayout.text} control={<Radio size='small' color='primary' />} label="Content only" />
            </Grid>
          </Grid>
          </RadioGroup>
        </FormControl> 
        <Typography variant='subtitle1' color='primary'>Widget Position</Typography>
        <FormControl component="fieldset" style={{ width: '100%' }} hidden>
          <RadioGroup value={payload.position || ''} onChange={this.handleChange}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel name='position' value={widgetPosition.bottomLeft} control={<Radio size='small' color='primary' />} label="Bottom Left" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel name='position' value={widgetPosition.bottomRight} control={<Radio size='small' color='primary' />} label="Bottom Right" />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant='subtitle1' color='primary'>Horizontal Margin</Typography>
            <TextField
              className={classes.themeInput}
              margin="dense"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={payload.horizontal_margin}
              name='horizontal_margin'
              onChange={this.handleChange}
              variant='outlined'
              fullWidth
            /> 
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' color='primary'>Vertical Margin</Typography>
            <TextField
              className={classes.themeInput}
              margin="dense"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={payload.vertical_margin}
              name='vertical_margin'
              onChange={this.handleChange}
              variant='outlined'
              fullWidth
            /> 
          </Grid>
        </Grid>
        <Typography variant='subtitle1' color='primary'>Content</Typography>
        <TextField
          className={classes.themeInput}
          // label='Text'
          margin="dense"
          type="text"
          value={payload.launcher_content}
          name='launcher_content'
          onChange={this.handleChange}
          variant='outlined'
          fullWidth
        /> 

        <Typography  variant='subtitle1' color='primary'>Icon</Typography>
        <div style={{marginTop: '10px'}}>
          <ToggleButton selected={payload.material_id === crown} size='large' style={{marginRight: '5px'}} onChange={() => this.handleIconClick(crown)}>
            <Crown />
          </ToggleButton>
          <ToggleButton selected={payload.material_id === gift} size='large' style={{marginRight: '5px'}} onChange={() => this.handleIconClick(gift)}>
            <Gift />
          </ToggleButton>
          <ToggleButton selected={payload.material_id === heart} size='large' style={{marginRight: '5px'}} onChange={() => this.handleIconClick(heart)}>
            <Heart />
          </ToggleButton>
          <ToggleButton selected={payload.material_id === star} size='large' style={{marginRight: '5px'}} onChange={() => this.handleIconClick(star)}>
            <Star />
          </ToggleButton>
          <ToggleButton selected={payload.material_id === tag} size='large' style={{marginRight: '5px'}} onChange={() => this.handleIconClick(tag)}>
            <TagHeart />
          </ToggleButton>
        </div>
      </Container>
    )
  }
}
export default WidgetForm