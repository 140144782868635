import React, { Component } from 'react'
import {
  Route,
  Switch,
  withRouter,
  BrowserRouter
} from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles';
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Auth from '../containers/Auth';
import Register from '../containers/Register';
import Home from '../containers/Home'
import Report from '../containers/Report'
import Points from '../containers/Points';
import Referrals from '../containers/Referrals'
import Widget from '../containers/Widget';
import Popup from '../containers/Popup';
import Customers from '../containers/Customers'
import RevenueReport from '../components/report/RevenueReport';
import CustomerReport from '../components/report/CustomerReport';
import ReferralReport from '../components/report/ReferralReport';
import Activity from '../containers/Activity'
import PointsEarnActivity from '../components/activity/PointsEarnActivity'
import PointsRedeemActivity from '../components/activity/PointsRedeemActivity'
import ReferralActivity from '../components/activity/ReferralActivity'
import EnableCustomerMessage from '../components/EnableCustomerMessage';


const styles = theme => ({
  root: {
    display: 'flex',
  },
  // appBarSpacer: theme.mixins.toolbar,
  appBarSpacer: {
    minHeight: 50
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
});


@inject('CommonStore')
@withStyles(styles)
@withRouter
@observer
class Router extends Component {

  render() {

    const {classes} = this.props

    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path='/auth' component={Auth} />
            <Route exact path='/register' component={Register} />
            <div className={classes.root}>

            <Sidebar />
            <Navbar />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <EnableCustomerMessage />
              <Route exact path='/report/revenue' render={() => {
                return (
                  <Report><RevenueReport /></Report>
                )
              }} />
              <Route exact path='/report/customer' render={() => {
                return (
                  <Report><CustomerReport /></Report>
                )
              }} />
              <Route exact path='/report/referral' render={() => {
                return (
                  <Report><ReferralReport /></Report>
                )
              }} />
              <Route exact path='/activity/points_redeem' render={() => {
                return (
                  <Activity><PointsRedeemActivity /></Activity>
                )
              }} />
              <Route exact path='/activity/points_earn' render={() => {
                return (
                  <Activity><PointsEarnActivity /></Activity>
                )
              }} />
              <Route exact path='/activity/referral' render={() => {
                return (
                  <Activity><ReferralActivity /></Activity>
                )
              }} />
              <Route exact path='/point' component={Points} />
              <Route exact path='/referral' component={Referrals} />
              <Route exact path='/widget' component={Widget} />
              <Route exact path='/popup' component={Popup} />
              <Route exact path='/customer' component={Customers} />
              <Route exact path='/' component={Home} />
            </main>
            </div>
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

export default Router