import React, { Component } from 'react'
import clsx from 'clsx'
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Overview from './Overview'
import RevenueChart from './RevenueChart'
import VisitChart from './VisitChart'
import RewardList from './RewardList'
import PointsEarning from './PointsEarning'
import { Typography } from '@material-ui/core'
import { priceOnlyFormat } from '../../utils/priceFormat'

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  fixedOverviewHeight: {
    height: 160,
  },
  fixedChartHeight: {
    height: 280
  },
  fixedCustomersHeight: {
    height: 320
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }
})

@withStyles(styles)
@inject('NavStore', 'HomeStore', 'ReportTabStore', 'ActivityStore')
@observer
class Dashboard extends Component {

  componentDidMount() {
    this.props.HomeStore.fetchCustomerOverview()
    this.props.HomeStore.fetchRevenueOverview()
    this.props.HomeStore.fetchVisitOverview()
    this.props.HomeStore.fetchRewardOverview()
    this.props.HomeStore.fetchPointsEarningActivity()
    this.props.HomeStore.fetchRewardsRedeemActivity()
  }

  handleCustomerOverviewClick = () => {
    this.props.ReportTabStore.setRoute('customer') 
  }

  handleRewardOverviewClick = () => {
    this.props.ActivityStore.setRoute('points_earn') 
  }

  handleVisitOverviewClick = () => {
    this.props.ReportTabStore.setRoute('referral')
  }

  handleRevenueOverviewClick = () => {
    this.props.ReportTabStore.setRoute('revenue')
  }

  render() {

    const { classes } = this.props
    const fixedOverviewHeightPaper = clsx(
      classes.paper,
      classes.fixedOverviewHeight
    )
    const fixedChartHeightPaper = clsx(
      classes.paper,
      classes.fixedChartHeight
    )
    const fixedCustomersHeightPaper = clsx(
      classes.paper,
      classes.fixedCustomersHeight
    )

    const { customerOverview, revenueOverview, visitOverview, rewardOverview } = this.props.HomeStore

    return (
      <Container maxWidth='xl' className={classes.container}>
        <Typography variant='h5' color='textPrimary'>Here's what's happening</Typography>
        <Typography variant='subtitle2' color='textSecondary' gutterBottom>Last 7 days</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={fixedOverviewHeightPaper}>
              <Overview 
                title='New customers'
                data={customerOverview}
                to='/report/customer'
                viewText='View customer analytics'
                onClick={this.handleCustomerOverviewClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={fixedOverviewHeightPaper}>
              <Overview 
                title='Rewards issued'
                data={rewardOverview}
                to='/activity/points_earn'
                viewText='View rewards analytics'
                onClick={this.handleRewardOverviewClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={fixedOverviewHeightPaper}>
              <Overview 
                title='New visits'
                data={visitOverview}
                to='/report/referral'
                textSecondary='via referral and share'
                viewText='View referral analytics'
                onClick={this.handleVisitOverviewClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={fixedOverviewHeightPaper}>
              <Overview 
                title='Revenue generated'
                data={`$${priceOnlyFormat(revenueOverview)}`}
                to='/report/revenue'
                viewText='View revenue analytics'
                onClick={this.handleRevenueOverviewClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Paper className={fixedChartHeightPaper}>
              <RevenueChart />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Paper className={fixedChartHeightPaper}>
              <VisitChart />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Paper className={fixedCustomersHeightPaper}>
              <PointsEarning />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Paper className={fixedCustomersHeightPaper}>
              <RewardList />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }
}
export default Dashboard
