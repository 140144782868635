import React, { Component } from 'react';
import DateRangePicker from '../DateRangePicker';
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  form: {
    display: 'inline-flex',
  },
  input: {
    width: 200,
    marginLeft: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2)
  }
})

@withStyles(styles)
@inject('ReferralReportStore')
@observer
class ReferralFilter extends Component {

  handleDateRangeOnChange = dateRange => {
    const [begin, end] = dateRange
    this.props.ReferralReportStore.dateRangeOnChange(begin, end)
  }

  handleGroupByChange = e => {
    this.props.ReferralReportStore.setGroupBy(e.target.value)
  }

  handleFilterClick = e => {
    this.props.ReferralReportStore.fetchData()
  }

  render() {

    const { classes } = this.props
    const { dateRangeBegin, dateRangeEnd, groupBy } = this.props.ReferralReportStore
    const dateRange = [dateRangeBegin, dateRangeEnd]

    return (
      <Paper className={classes.paper}>
        <div className={classes.form}>
            <DateRangePicker 
              value={dateRange} 
              onChange={this.handleDateRangeOnChange} 
              />
            <TextField 
              select
              label='Group by'
              value={groupBy}
              onChange={this.handleGroupByChange}
              variant='outlined'
              size='small'
              className={classes.input}
            >
              <MenuItem value='hour'>Hour</MenuItem>
              <MenuItem value='date'>Date</MenuItem>
              <MenuItem value='month'>Month</MenuItem>
            </TextField>
            <Button
              size='large'
              variant='outlined'
              color='primary'
              onClick={this.handleFilterClick}
              className={classes.button}
            >
              Filter
            </Button>
          </div>
      </Paper>
    );
  }
}

export default ReferralFilter;