import React, { Component } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import { priceFormat } from '../../utils/priceFormat'

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
})

function placeOrderItemContent(placeOrderRule, shop, point_alias) {
  if (placeOrderRule.place_order_type === 'fix_amount') {
    return `${placeOrderRule.place_order_value} ${point_alias}`
  } else if (placeOrderRule.place_order_type === 'points_per_spent') {
    return `${placeOrderRule.place_order_value} ${point_alias} for every ${priceFormat(1, shop)} spent`
  }
}

@withStyles(styles)
@inject('CommonStore', 'EarnPointRuleStore', 'ShopStore')
@observer
class EarnRuleList extends Component {

  componentWillMount() {
    this.props.EarnPointRuleStore.fetchEarnPointRule()
  }

  handleEditClick = (ruleType) => {
    this.props.EarnPointRuleStore.setUpdateModalType(ruleType)
    this.props.EarnPointRuleStore.initUpdatePayload()
    this.props.EarnPointRuleStore.setUpdateModalOpen()
  }

  handleEnableClick = k => {
    this.props.EarnPointRuleStore.clearUpdatePayload()
    this.props.EarnPointRuleStore.updatePayloadOnChange(k, 1)
    this.props.EarnPointRuleStore.update()
  }

  handleDisableClick = k => {
    this.props.EarnPointRuleStore.clearUpdatePayload()
    this.props.EarnPointRuleStore.updatePayloadOnChange(k, 0)
    this.props.EarnPointRuleStore.setStatusModifyAlertOpen()
  }

  render() {
    const { classes } = this.props
    const { earnPointRule } = this.props.EarnPointRuleStore
    return (
      <List className={classes.root}>
        <Divider />
        <ListItem>
          <ListItemAvatar>
            <img src={require('../../static/shopping-cart.svg')} height='26' />
          </ListItemAvatar>
          
          <ListItemText
            primary='Place an order'
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  {placeOrderItemContent(earnPointRule, this.props.ShopStore.shop, earnPointRule.point_alias)}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            {earnPointRule.place_order_switch
              ? <Button size='small' onClick={() => this.handleDisableClick('place_order_switch')}>Disable</Button>
              : <Button size='small' color='secondary' onClick={() => this.handleEnableClick('place_order_switch')}>Enable</Button>
            }
            <Button 
              size='small' 
              variant='contained' 
              color='primary' 
              onClick={() => this.handleEditClick('placeAnOrder')}
              style={{marginLeft: 10}}
            >
              Edit
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar>
            <img src={require('../../static/birthday-cake.svg')} height='26' />
          </ListItemAvatar>
          <ListItemText
            primary="Celebrate a birthday"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  {earnPointRule.birthday_value} {earnPointRule.point_alias}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            {earnPointRule.birthday_switch
              ? <Button size='small' onClick={() => this.handleDisableClick('birthday_switch')}>Disable</Button>
              : <Button size='small' color='secondary' onClick={() => this.handleEnableClick('birthday_switch')}>Enable</Button>
            }
            <Button 
              size='small' 
              variant='contained' 
              color='primary' 
              onClick={() => this.handleEditClick('birthday')}
              style={{marginLeft: 10}}
            >
              Edit
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <img src={require('../../static/store.svg')} height='26' />
          </ListItemAvatar>
          <ListItemText
            primary="Signup"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  {earnPointRule.register_value} {earnPointRule.point_alias}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            {earnPointRule.register_switch
              ? <Button size='small' onClick={() => this.handleDisableClick('register_switch')}>Disable</Button>
              : <Button size='small' color='secondary' onClick={() => this.handleEnableClick('register_switch')}>Enable</Button>
            }
            <Button 
              size='small'
              color='primary'
              variant='contained'
              onClick={() => this.handleEditClick('register')}
              style={{marginLeft: 10}}
            >
              Edit
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </List>
    )
  }
}
export default EarnRuleList