import React, { Component } from 'react'

class Register extends Component {
  render() {
    return (
      <div>
        this is register page
      </div>
    )
  }
}
export default Register