import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { withStyles, withTheme } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import { Tooltip, XAxis, YAxis, Label, ResponsiveContainer, BarChart, Bar, Legend } from 'recharts'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 360
  },
})


@withTheme
@withStyles(styles)
@inject('RevenueReportStore')
@observer
class RevenueChart extends Component {

  ascSort = source => {
    const data = source.sort(this._compare)
    return data
  }

  _compare = (a, b) => {
    if (a.datetime < b.datetime) {
      return -1
    }
    if (a.datetime > b.datetime) {
      return 1
    }
    return 0
  }

  render() {
    
    const { classes, theme } = this.props
    const { data } = this.props.RevenueReportStore

    return (
      <Paper className={classes.paper}>
        <ResponsiveContainer>
          <BarChart
            data={this.ascSort(data)}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="datetime" stroke={theme.palette.text.secondary} />
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                >
                Revenue
              </Label>
            </YAxis>
            <Tooltip />
            <Legend stroke={theme.palette.primary.secondary} />
            <Bar dataKey='points_redeem_revenue' stackId='a' fill={theme.palette.primary.light} />
            <Bar dataKey='referral_friend_revenue' stackId='a' fill={theme.palette.error.light} />
            <Bar dataKey='referral_customer_revenue' stackId='a' fill={theme.palette.warning.light} />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    )
  }
}
export default RevenueChart