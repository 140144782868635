import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { withStyles } from '@material-ui/styles';
import { observer, inject } from 'mobx-react'
import User from './navbar/User';
import Title from './navbar/Title';


const drawerWidth = 240;

const styles = theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
});


@withStyles(styles)
@inject('CommonStore')
@observer
class Navbar extends React.Component {

  handleSidebarOpen = () => {
    this.props.CommonStore.setSidebarToggle()
  }

  render() {
    const { classes } = this.props
    const { sidebarToggle } = this.props.CommonStore
    
    return (
      <AppBar color="primary" position="absolute" className={clsx(classes.appBar, sidebarToggle && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleSidebarOpen}
            className={clsx(classes.menuButton, sidebarToggle && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>

          <Title />

          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <User />
        </Toolbar>
      </AppBar>
    )
  }
}

export default Navbar