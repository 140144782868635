import React, { Component } from 'react'
import './popup.css'
import { inject, observer } from 'mobx-react'

@inject('PopupStore')
@observer
class Preview extends Component {
  render() {

    const { theme_color, font_color } = this.props.PopupStore.payload

    return (
      <div id='shopaghetti-app-container'>
      <div id="shopaghetti-app" className="shopaghetti-fadein">
      <header className="shopaghetti-header-wrap" style={{backgroundColor: theme_color}}>
        <img src="https://cdn.ethalt.com/giftBox.svg" className="shopaghetti-image" alt='' />
        <button className="shopaghetti-cancel-btn"></button>
      </header>
      <main className="shopaghetti-main-wrap">
        <h3 className="shopaghetti-main-title">Refer friends. Get rewards. </h3>
        <article className="shopaghetti-main-content">Give your friends ¥30 off all products. Once your friends order from your invite link, you get ¥30 off coupon.</article>
        <button className="shopaghetti-copy-btn" style={{backgroundColor: theme_color, color: font_color}}>Get invite Link</button>
      </main>
      <footer className="shopaghetti-footer-wrap">
        <p>share on your social media</p>
        <ul className="shopaghetti-nav">
          <li><a className="shopaghetti-fbIcon" type='button'></a></li>
          <li><a className="shopaghetti-twIcon" type='button'></a></li>
          <li><a className="shopaghetti-emIcon" type='button'></a></li>
        </ul>
      </footer>
    </div>
      </div>
    )
  }
}
export default Preview