import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { observer, inject } from 'mobx-react'


@inject('EarnPointRuleStore')
@observer
class EarnRuleUpdateModal extends Component {

  handleClose = () => {
    this.props.EarnPointRuleStore.setUpdateModalClose()
  }

  summary = () => {
    return 'summmmmmmary'
  }

  handleTypeClick = e => {
    this.props.EarnPointRuleStore.updatePayloadOnChange('place_order_type', e.target.value)
  }

  handlePointsChange = e => {
    this.props.EarnPointRuleStore.updatePayloadOnChange(e.target.id, e.target.value)
  }

  handleSave = () => {
    this.props.EarnPointRuleStore.update()
  }

  earnPointInput = () => {
    switch (this.props.EarnPointRuleStore.updateModalType) {
      case 'register':
        return (
          <TextField
            autoFocus
            margin="dense"
            variant='outlined'
            id="register_value"
            type="number"
            value={this.props.EarnPointRuleStore.updatePayload.register_value}
            onChange={this.handlePointsChange}
            fullWidth
          />
        )
      case 'birthday':
        return (
          <TextField
            autoFocus
            margin="dense"
            variant='outlined'
            id="birthday_value"
            type="number"
            value={this.props.EarnPointRuleStore.updatePayload.birthday_value}
            onChange={this.handlePointsChange}
            fullWidth
          />
        )
      case 'placeAnOrder':
        return (
          <TextField
            autoFocus
            margin="dense"
            variant='outlined'
            id="place_order_value"
            type="number"
            value={this.props.EarnPointRuleStore.updatePayload.place_order_value}
            onChange={this.handlePointsChange}
            fullWidth
          />
        )
      default:
        return ''
    }
  }

  render() {

    const { 
      updateModalOpen,
      updateModalType,
      updateModalTitle,
      updatePayload,
    } = this.props.EarnPointRuleStore
    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={updateModalOpen}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{updateModalTitle}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            {this.summary()}
          </DialogContentText> */}
          {updateModalType === 'placeAnOrder'
            ? <div>
              <Typography  variant='subtitle1' gutterBottom color='primary'>Earn type</Typography>
              <FormControl component="fieldset" hidden>
                <RadioGroup aria-label="gender" value={updatePayload.place_order_type} onChange={this.handleTypeClick}>
                  <FormControlLabel value="points_per_spent" control={<Radio size='small' color='primary' />} label="Points per order spent (recommended)" />
                  <FormControlLabel value="fix_amount" control={<Radio size='small' color='primary' />} label="Fixed amount" />
                </RadioGroup>
              </FormControl>
            </div>
            : ''
          }
          
          <Typography  variant='subtitle1' gutterBottom color='primary'>Earn Points</Typography>
            {this.earnPointInput()}
            
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="default">
            Close
          </Button>
          <Button onClick={this.handleSave} color="primary" variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default EarnRuleUpdateModal