import React, { Component } from 'react'
import {
  Button,
  Collapse
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { observer, inject } from 'mobx-react'


@inject('NotificationStore', 'ShopStore')
@observer
class EnableCustomerMessage extends Component {

  handleClose = () => {
    this.props.NotificationStore.setMessageClose()
    window.open(`https://${this.props.ShopStore.shop.myshopify_domain}/admin/settings/checkout`, '_blank') 
  }

  render() {
    const { messageOpen } = this.props.NotificationStore

    return (
      <Collapse in={messageOpen}>
        <Alert
          variant='standard'
          severity='warning'
          open={messageOpen}
          action={
            <Button color='secondary' onClick={this.handleClose}>ENABLE</Button>
          }
          >
          <AlertTitle>Enable customer</AlertTitle>
          To allow members to join your program, customer accounts must be optional or required on your Shopify Store.
        </Alert>
      </Collapse>
    )
  }
}

export default EnableCustomerMessage